import React, { Suspense } from "react";

import { useTranslation } from "react-i18next";
import { Navbar } from "../../components";
import Footer from "../footer/Footer";
import { ProductDetail } from "components/product-detail/ProductDetail";
import { CartItemList } from "components/cart/CartItemList";

export const CartSummary = () => {
  return (
    <div>
      <Suspense fallback={null}>
        <div>
          <Navbar />
          <CartItemList />
        </div>

        <Footer />
      </Suspense>
    </div>
  );
};
