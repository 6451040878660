import React, { useEffect, useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SignResponse } from "../signin/sign_response";
import "../signin/signin.css";

interface ServerErrorProps {
  error: string;
}
export const NotFoundPage = ({ match, location }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const msgPageNotFound = t("page_not_found");
  const msgGoHomePage = t("go_home_page");

  const gotoHomePage = () => navigate("/");
  const error = searchParams.get("error");
  console.log(`error message:${error}`);

  const pageNotFound: JSX.Element = (
    <div>
      <div className="warn">
        <p>{msgPageNotFound}</p>
      </div>
      <div className="spacer" />
      <button type="button" className="btn-warn" onClick={gotoHomePage}>
        {msgGoHomePage}
      </button>
    </div>
  );
  return <SignResponse messageBox={pageNotFound} />;
};
