import React from "react";
import { useNavigate } from "react-router-dom";
import "./signin.css";
import { useTranslation } from "react-i18next";
import { useForm, SubmitHandler } from "react-hook-form";

import { useAppSelector, useAppDispatch } from "../../app/hooks";
import SignForm from "./Sign_form";
import { ajaxPost } from "service/http";

type ForgetPasswordFields = {
  email: string;
};
export const ForgetPasswordPage = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const emailRequired = t("emailIsRequired");
  const invalidEmailAddress = t("invalidEmailAddress");
  const resetPassword = t("reset_password");

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<ForgetPasswordFields>();
  const onSubmit: SubmitHandler<ForgetPasswordFields> = async (data) => {
    console.log(`submitted data:${JSON.stringify(data)}`);
    //dispatch(singupAsync(data));
    try {
      const resp = await ajaxPost("/api/forgetpassword", data);
      navigate("/forgetpassword-success");
    } catch (e) {
      console.log(`failed to request to rest password`);
    }
  };

  const fields = (
    <div className="fields-container">
      <div className="horizontalLine"></div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <input
          type="text"
          key="emailkey"
          placeholder="Enter Email"
          {...register("email", {
            required: true,
            pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
          })}
        />
        {errors.email?.type === "required" && (
          <p className="error-message">{emailRequired}</p>
        )}
        {errors.email?.type === "pattern" && (
          <p className="error-message">{invalidEmailAddress}</p>
        )}

        <button type="submit" className="btn-login">
          {resetPassword}
        </button>
      </form>
    </div>
  );
  return <SignForm formFieldsEle={fields} />;
};
