import React from 'react'
import { useNavigate } from 'react-router-dom';

import './header.css';
import {useTranslation} from 'react-i18next';
import phoneTakePic from '../../assets/phoneTakePic.jpg';

const Header = () => {
    const {t} = useTranslation();
    const handleGetStarted = () => {
        window.location.href="#downloads";
    }
  
    return (
        <div className="everpx__header section__padding" id="home">
            <div className="everpx__header-content">
                <h1 className="gradient__text">{t('header_title')}</h1>
                <p>{t('header_content')}</p>
                <div className="everpx__header-content__input">
                    <button type="button" onClick={handleGetStarted}>{t('get_started')}</button>
                </div>
              
            </div>
            <div className="everpx__header-image">
                <img src = {phoneTakePic} />
            </div>
        </div>
    )
}

export default Header
/*
 <div className="everpx__header-content__input">
                   <input type="email" placeholder={t('your_email_address')} />
                   <button type="button">{t('get_started')}</button>
               </div>
*/
