import { useAppSelector } from "app/hooks";
import Navbar from "components/navbar/Navbar";
import { selectEmail, selectJwtToken } from "components/signin/sigin_slice";
import { Footer } from "containers";
import React, { Suspense, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ajaxGetWithJWT, ajaxPostWithJWT } from "service/http";
import "./youraccount.scss";
type BillAddress = {
  billAddress: string;
};
export const YourBillAddress = () => {
  const { t } = useTranslation();
  const jwtToken = useAppSelector(selectJwtToken);
  const userEmail = useAppSelector(selectEmail);

  const [editMode, setEditMode] = useState<boolean>(false);
  const [msg, setMsg] = useState<string | null>(null);

  const [billAddress, setBillAddress] = useState<BillAddress | null>(null);

  const navigate = useNavigate();

  const getYourBillAddress = () => {
    ajaxGetWithJWT("/api/get-your-billaddress", jwtToken)
      .then((res) => {
        if (res.data) {
          const billAddress = res.data as string;
          setBillAddress({ billAddress: billAddress });
        }
      })
      .catch((error) => {
        navigate("/signin");
        /*setMsg(t("error_get_your_account"));
        console.log(
          `Error when retrieving customer bill address:${error.data}`
        );*/
      });
  };

  useEffect(() => {
    if (jwtToken === null) {
      navigate("/signin");
      return;
    }
    getYourBillAddress();
  }, []);

  const YourBillAddressForm = ({ billAddress }) => {
    const {
      register,
      handleSubmit,
      watch,
      formState: { errors },
    } = useForm<BillAddress>({ defaultValues: billAddress });
    const saveYourBillAddress = (data) => {
      ajaxPostWithJWT("/api/save-your-billaddress", data, jwtToken)
        .then((res) => {
          if (res.data) {
            const billAddress = res.data as string;
            setBillAddress({ billAddress: billAddress });
            setEditMode(false);
          }
        })
        .catch((error) => {
          navigate("/signin");
          /* setMsg(t("error_save_your_billaddress"));
          setEditMode(false);
          console.log(
            `Error when retrieving customer bill address:${error.data}`
          );
          */
        });
    };
    const onSubmit: SubmitHandler<{ billAddress: string }> = (data) => {
      console.log(`submitted data:${JSON.stringify(data)}`);
      saveYourBillAddress(data);
    };
    const billAddressIsRequired = t("bill_address_required");
    return (
      <div className="account-form-container">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex-col-start">
            <label className="fieldLabel margin-top">{t("bill_to")}</label>
            <textarea
              rows={5}
              key="billAddressKey"
              autoFocus={true}
              {...register("billAddress", {
                required: true,
                maxLength: 500,
              })}
            />
            {errors.billAddress?.type === "required" && (
              <p className="error-message">{billAddressIsRequired}</p>
            )}
          </div>
          <div className="flex-row-sb">
            <a className="link" onClick={() => setEditMode(false)}>
              {t("cancel_cap")}
            </a>
            <button type="submit" className="btn-save">
              {t("save_cap")}
            </button>
          </div>
        </form>
      </div>
    );
  };
  const AccountItem = ({ title, value, pre }) => {
    return (
      <div className="account-item">
        <div className="account-item-title">{title}</div>
        <div className="account-item-value">
          {pre ? <pre>{value}</pre> : <>{value}</>}
        </div>
      </div>
    );
  };
  const yourBillAddressContainer = (
    <div className="your-account-container">
      <div className="flex-row-sb underline">
        <h2>{t("your_billaddress_cap")}</h2>
        {!editMode && billAddress && (
          <span>
            <a className="link" onClick={() => setEditMode(true)}>
              {t("edit_cap")}
            </a>
          </span>
        )}
      </div>
      <div className="account-item-container">
        {msg && <p className="error-message">{msg}</p>}
      </div>
      <div className="account-item-container">
        <AccountItem title={t("email")} value={userEmail} pre={false} />
        {billAddress && !editMode ? (
          <AccountItem
            title={t("bill_to")}
            value={billAddress.billAddress}
            pre={true}
          />
        ) : (
          !editMode && (
            <div>
              <span>{t("bill_address_not_setup")}</span>.&nbsp;&nbsp;
              <a className="link" onClick={() => setEditMode(true)}>
                {t("setup_now")}
              </a>
            </div>
          )
        )}
      </div>
      {editMode && <YourBillAddressForm billAddress={billAddress} />}
    </div>
  );

  return (
    <>
      <div>
        <Suspense fallback={null}>
          <div>
            <Navbar />
            {yourBillAddressContainer}
          </div>

          <Footer />
        </Suspense>
      </div>
    </>
  );
};
