import React, { useEffect } from "react";
import "./download.css";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Product, ProductImage } from "components/product-detail/product";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  getAllProductsAsync,
  selectProducts,
} from "components/product-detail/product_slice";

const Plan = ({ productId, title, features, price, currency }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleCick = () => {
    if (productId === 0) {
      navigate("/download");
    } else {
      navigate(`/productInfo/${productId}`);
    }
  };
  return (
    <div className="everpx__plans-half">
      <ul className="everpx__plans-plan  everpx__plans-white ">
        <li
          key="feature-title"
          className="everpx__plans-blue everpx__plans-large everpx__plans-padding-32 everpx__plans-li"
        >
          {title}
        </li>
        {features.map((f, i) => (
          <li
            key={`feature-${title}-${i}`}
            className="everpx__plans-padding-16 everpx__plans-li"
          >
            {f}
          </li>
        ))}
      </ul>

      <div className="buy-section">
        <div className="buy-price">
          <p className="everpx__plans-xlarge">
            ${price / 100.0}{" "}
            <span className="everpx_plans_price_frequency">
              {" "}
              {productId === 3 ? t("per_year") : ""}
            </span>
          </p>
        </div>

        <button className="buy-button" onClick={handleCick}>
          {productId == 0 ? t("download") : t("buy")}
        </button>
      </div>
    </div>
  );
};

const Downloads = () => {
  const { t } = useTranslation();

  const featureFree = {
    productId: 0,
    title: t("free"),
    features: [
      t("feature_free_mobile"),
      t("feature_storage_disks_2"),
      t("feature_community_support"),
    ],
    price: 0,
    currency: "$",
    priceFrequency: "",
    buyDownload: "d",
  };
  const featureStandard = {
    productId: 1,
    title: t("standard"),
    features: [
      t("feature_all_features_community"),
      t("feature_storage_disks_unlimited"),
      t("feature_cold_disk"),
      t("feature_auto_upgrade"),
      t("feature_massive_import"),
      t("feature_one_year_support"),
    ],
    price: 29.99,
    currency: "$",
    priceFrequency: "One Time",
    buyDownload: "b",
  };
  const featurePremium = {
    productId: 2,
    title: t("premium"),
    features: [
      t("feature_all_features_community"),
      t("feature_storage_disks_unlimited"),
      t("feature_cold_disk"),
      t("feature_auto_upgrade"),
      t("feature_massive_import"),
      t("feature_one_year_support"),
      t("feature_remote_backup"),
      t("feature_cloud_backup"),
    ],
    price: 49.99,
    currency: "$",
    priceFrequency: "One Time",
  };

  /**
   *  "feature_ticketed_support":"Ticketed Support",
    "feature_optional_db_backup":"Optional database remote backup",
    "feature_remote_troubleshooting":"Remote troubleshooting with support VPN",
    "feature_health_check":"Automatic Health Check and Alert",
    "feature_free_upgrade":"Free to upgraded to latest version",
   */
  const featureSupport = {
    productId: 3,
    title: t("techSupport"),
    features: [
      t("feature_ticketed_support"),
      t("feature_optional_db_backup"),
      t("feature_remote_troubleshooting"),
      t("feature_health_check"),
      t("feature_free_upgrade"),
    ],
    price: 23.99,
    currency: "$",
    priceFrequency: "Per Year",
  };
  const everpxBox = {
    title: t("everpxBox"),

    features: [
      t("preInstall"),
      t("fanlessMiniPc"),
      t("lowPower"),
      t("fanLess"),
      t("boxSpec"),
    ],
    price: 159.99,
    currency: "$",
    priceFrequency: "One Time",
    buyDownload: "b",
  };
  const features = [
    featureFree,
    featureStandard,
    featurePremium,
    featureSupport,
  ];

  const allProducts = useAppSelector(selectProducts) as Product[];
  const dispatch = useAppDispatch();

  useEffect(() => {
    // if (!allProducts || allProducts.length === 0) {
    dispatch(getAllProductsAsync());
    // }
  }, []);

  return (
    <div className="everpx__plans section__margin" id="downloads">
      <div className="everpx__whatiseverpx-heading">
        <h1 className="gradient__text">{t("downloadStation")}</h1>
      </div>

      <div className="everpx__plans-content">
        {allProducts && allProducts.map((feature, i) => (
          <Plan
            productId={feature.productId}
            title={feature.title}
            price={feature.price}
            features={feature.features}
            currency={feature.currency}
          />
        ))}
      </div>
    </div>
  );
};

export default Downloads;
