import React, { Suspense, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import "./faq.scss";
import { useTranslation } from "react-i18next";
import { Navbar } from "../../components";
import Footer from "../../containers/footer/Footer";
import { FAQ, FAQItem } from "./FaqItem";
import { ajaxGet } from "service/http";
import { FaqVO } from "app/models/faq_vo";

export const Faq = () => {
  const { t } = useTranslation();
  const [faqs, setFaqs] = useState<FaqVO[]>([]);

  useEffect(() => {
    ajaxGet("/api/get-all-faqs").then((r) => {
      setFaqs(r.data as FaqVO[]);
    });
  }, []);

  const FaqContainer = () => (
    <div className="faq-container">
      <h2>FAQ</h2>
      <div className="underline"></div>
      {faqs.map((f) => (
        <FAQItem {...f} />
      ))}
    </div>
  );

  //className="gradient__bg"
  return (
    <>
      <div>
        <Suspense fallback={null}>
          <div>
            <Navbar />
            <FaqContainer />
          </div>

          <Footer />
        </Suspense>
      </div>
    </>
  );
};
