import React, { useEffect, useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SignResponse } from "./sign_response";
import "./signin.css";

interface ServerErrorProps {
  error: string;
}
export const ResetpasswordFailurePage = ({ match, location }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const msgGotoForgetPassword = t("goto_forgetpassword");

  const gotoForgetPassword = () => navigate("/forget-password");
  const error = searchParams.get("error");
  console.log(`error message:${error}`);

  const activationSuccess: JSX.Element = (
    <div>
      <div className="warn">
        <p>{error}</p>
      </div>
      <div className="spacer" />
      <button type="button" className="btn-warn" onClick={gotoForgetPassword}>
        {msgGotoForgetPassword}
      </button>
    </div>
  );
  return <SignResponse messageBox={activationSuccess} />;
};
