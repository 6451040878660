import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
//import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {useTranslation} from 'react-i18next';
import "./feedback.css";
/*



const Footer = () =>{
    
*/
function Feedback() {

    const {t} = useTranslation();

	const [info, setInfo] = useState({
		name: '',
		email: '',
		message: '',
		phone: '',
		uploadedFiles: [],
		buttonText: t('submit'),
		uploadPhotosButtonText: 'Upload files',
	});
	const { name, email, message, phone, uploadedFiles, buttonText, uploadPhotosButtonText } = info;
	const { REACT_APP_CLOUD_NAME, REACT_APP_UPLOAD_SECRET } = process.env;

	const handleSubmit = e => {
		e.preventDefault();

		setInfo({ ...info, buttonText: t('sending') });

		axios({
			method: 'POST',
			url: '/api/feedback',
			data: { name, email, message, phone },
		})
			.then(res => {
				console.log(`feedback response:${JSON.stringify(res.data)}`);
				//if (res.data.name){
                  toast(t('thanks_for_feedback'), {
						position: 'top-right',
						autoClose: 15000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
					});
			//	}
					
				setInfo({
					...info,
					name: '',
					phone: '',
					email: '',
					message: '',
					buttonText: t('submited'),
					uploadPhotosButtonText: 'Uploaded',
				});
			})
			.catch(err => {
				if (err.response.data.error) toast.error(t('failed_try_again'));
			});
	};

	const handleChange = name => e => {
		setInfo({ ...info, [name]: e.target.value });
	};

	const uploadWidget = () => {
		window.cloudinary.openUploadWidget(
			{
				cloud_name: REACT_APP_CLOUD_NAME,
				upload_preset: REACT_APP_UPLOAD_SECRET,
				tags: ['images'],
			},
			function (error, result) {
				if (error) console.log(error);
				setInfo({ ...info, uploadPhotosButtonText: 'Uploaded' });
			}
		);
	};

	return (
		<div>
			<Form onSubmit={handleSubmit}>
				{/* Name */}
				<Form.Group className="mb-3">
					<Form.Label>{t('name')}</Form.Label>
					<Form.Control
						value={name}
						onChange={handleChange('name')}
						type='name'
						placeholder={t('enter_your_name')}
						required
					/>
				</Form.Group>
				{/* Phone */}
				<Form.Group>
					<Form.Label>{t('phone')}</Form.Label>
					<Form.Control
						value={phone}
						onChange={handleChange('phone')}
						type='phone'
						placeholder={t('enter_your_phone')}
						required
					/>
				</Form.Group>
				{/* email */}
				<Form.Group>
					<Form.Label>{t('email')}</Form.Label>
					<Form.Control
						value={email}
						onChange={handleChange('email')}
						type='email'
						placeholder={t('enter_email')}
						required
					/>
				</Form.Group>
				{/* text area */}
				<Form.Group>
					<Form.Label>{t('description')}</Form.Label>
					<Form.Control
						onChange={handleChange('message')}
						as='textarea'
						value={message}
						rows={3}
                        
						required
					/>
				</Form.Group>
				
				<div className="feedback-submit-button">

				
					<Button type='submit' className="feedback-submit-button">{buttonText}</Button>
				</div>
			</Form>
		</div>
	);
}

export default Feedback;