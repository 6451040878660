import React, { Suspense, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { Navbar } from "../../components";
import Footer from "../footer/Footer";
import {
  getAllProductsAsync,
  ProductRepo,
  selectProducts,
} from "components/product-detail/product_slice";
import { ProductDetail } from "components/product-detail/ProductDetail";
import { useParams } from "react-router-dom";
import { Product, ProductImage } from "components/product-detail/product";
import { useAppDispatch, useAppSelector } from "app/hooks";

const ProductInfo = () => {
  const { productId } = useParams();
  const allProducts = useAppSelector(selectProducts) as Product[];
  const dispatch = useAppDispatch();

  useEffect(() => {
    // if (!allProducts || allProducts.length === 0) {
    dispatch(getAllProductsAsync());
    // }
  }, []);

  const productImages: ProductImage[] = [1, 2, 3, 4].map((index) => {
    return {
      id: index,
      img: `images/demo/image-product-${index}.jpg`,
      imgThumbnail: `images/demo/image-product-${index}-thumbnail.jpg`,
    };
  });
  const sampleProduct: Product = {
    productId: 1,
    name: "Everpx Station Professional License",
    title: "Everpx Station Professional License",
    price: 49.99,
    promotionPrice: 39.99,
    currency: "USD",
    images: productImages,
    features: ["Unlimited storage space", "Hot and code disk backup"],
    description:
      "Everpx Station Professional Edition provides full photo storage hot and cold disk backup and unlimited storage space",
  };

  console.log(`productId:${productId}`);
  let productIdNum = productId ? Number(productId) : 1;
  if (isNaN(productIdNum)) {
    productIdNum = 1;
  }

  //const product = ProductRepo.getProductById(productIdNum);
  const products: Product[] = allProducts
    ? allProducts.filter((p) => p.productId == productIdNum)
    : [];

  const product = products.length == 0 ? null : products[0];

  /* <img src = {torontoCNTower} /> */
  return (
    <>
      <div>
        <Suspense fallback={null}>
          <div>
            <Navbar />
            {product && <ProductDetail {...product} />}
          </div>

          <Footer />
        </Suspense>
      </div>
    </>
  );
};

export default ProductInfo;
