import { Navbar } from "components";
import Footer from "containers/footer/Footer";
import React, { Suspense } from "react";
import { useTranslation } from "react-i18next";
import { createSearchParams, Link, useNavigate } from "react-router-dom";
import "./download.css";
export const DownloadPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = { downloadtype: "st", version: "1.0", arch: "x86_64" };
  const linkTo = {
    pathname: "/api/download",
    query: { downloadtype: "st", version: "1.0", arch: "x86_64" },
  };
  const handleNav = () => {
    navigate({
      pathname: "/api/download",
      search: `?${createSearchParams(params)}`,
    });
  };
  const downloadUrl = `/api/download?${createSearchParams(params)}`;
  const download = (
    <div className="everpx__plans download-margin">
      <div className="everpx__whatiseverpx-heading">
        <h1 className="gradient__text">{t("everpx_station_download")}</h1>
      </div>
      <div className="download-container">
        <a href={downloadUrl}>Everpx Station Image(x86)</a>
        <span>sha256:4223fkkf2effff</span>
      </div>
    </div>
  );
  return (
    <>
      <div>
        <Suspense fallback={null}>
          <div>
            <Navbar />
            {download}
          </div>

          <Footer />
        </Suspense>
      </div>
    </>
  );
};
