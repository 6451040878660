import { useAppDispatch, useAppSelector } from "app/hooks";
import useMediaQuery from "hooks/userMediaQuery";
import React, { useState, useEffect } from "react";
import { Product } from "./product";
import "./productdetail.scss";
import {
  selectIsPhoneNave,
  selectShowCart,
  setShowLightbox,
} from "./product_slice";
//import { useGlobalContext } from "../context/context";
//import useMediaQuery from "../hooks/useMediaQuery";

const ProductImages = ({ images }: Product) => {
  const screen = useMediaQuery("(max-width: 768px)");
  //const phoneBtn = screen ? true : false;
  const phoneBtn = false;

  const dispatch = useAppDispatch();

  const showCart = useAppSelector(selectShowCart);
  const isPhoneNav = useAppSelector(selectIsPhoneNave);

  const [value, setValue] = useState(0);
  const [showBtn, setShowBtn] = useState(false);
  const [showPreview, setShowPreview] = useState(true);

  const previewImages = images;
  const btn = false;
  const previewIndex = previewImages.find((preview, index) => index === value);
  const checkNumber = (number) => {
    if (number > previewImages.length - 1) {
      return 0;
    }
    if (number < 0) {
      return previewImages.length - 1;
    }
    return number;
  };
  const nextPicture = () => {
    setValue((value) => {
      let newValue = value + 1;
      return checkNumber(newValue);
    });
  };
  const prevPicture = () => {
    setValue((value) => {
      let newValue = value - 1;
      return checkNumber(newValue);
    });
  };

  useEffect(() => {
    if (screen) {
      setShowBtn(true);
      setShowPreview(false);
    } else {
      setShowBtn(false);
      setShowPreview(true);
    }
  }, [screen]);

  const handleClickProdImg = () => {
    if (!!showBtn) {
      dispatch(setShowLightbox(true));
    }
  };
  return (
    <div className="product--image--container">
      {showBtn && (
        <div className="btn-container" style={{ top: "45%" }}>
          <button onClick={prevPicture} className="left-btn flex-ac-jc">
            <svg width="12" height="18" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M11 1 3 9l8 8"
                stroke="#000"
                stroke-width="3"
                fill="none"
                fillRule="evenodd"
              />
            </svg>
          </button>
          <button onClick={nextPicture} className="right-btn flex-ac-jc">
            <svg width="12" height="18" xmlns="http://www.w3.org/2000/svg">
              <path
                d="m2 1 8 8-8 8"
                stroke="#1D2026"
                stroke-width="3"
                fill="none"
                fillRule="evenodd"
              />
            </svg>
          </button>
        </div>
      )}

      <div
        className="preview-image"
        onClick={handleClickProdImg}
        style={!showBtn ? {} : { cursor: "pointer" }}
      >
        <img
          src={previewIndex?.img}
          alt="product image"
          className="prod-image"
        />
      </div>
      {!showBtn && (
        <div className="preview-images flex-ac-jb">
          {previewImages.map((previewImage, index) => (
            <div>
              <button key={previewImage.id} onClick={() => setValue(index)}>
                <img
                  src={previewImage?.imgThumbnail}
                  alt="product image"
                  className={value === index ? "active-image flex-ac-jc" : ""}
                />
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
  //
};

export default ProductImages;
