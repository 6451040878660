import React, { useEffect, useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { SignResponse } from "./sign_response";
import "./signin.css";

export const SignupSuccessPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const successSignup = t("successSignup");
  const goHomePage = t("go_home_page");

  const gotoHomePage = () => navigate("/");

  const activationSuccess: JSX.Element = (
    <div>
      <div className="success">
        <p>{successSignup}</p>
      </div>
      <div className="spacer" />
      <button type="button" className="btn-success" onClick={gotoHomePage}>
        {goHomePage}
      </button>
    </div>
  );
  return <SignResponse messageBox={activationSuccess} />;
};
