import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { ajaxPost } from 'service/http';
import { getHoursDifference, getMinutesDifference } from 'service/utils';
import { EmailPasswd } from './signup_slice';

export enum SignInStatus {
    SIGNED_IN = 'signed_in',
    SINGNING_IN = 'signing_in',
    NOT_SIGNED_IN = 'not_signed_in',
    FAILED = 'failed'

}

export interface SignInState {
    username: string | null;
    userId: number | null,
    email: string | null,
    status: SignInStatus,
    jwtToken: string | null,
    error: string | null,
    loginTime: string | null,
    redirectPath: string | null
}
export interface UserPasswd {
    userName: string,
    password: string
}

export interface GoogleProfile {
    email: string,
    name: string,
    givenName: string,
    familyName: string,
    imageUrl: string
}

const initialState: SignInState = {
    username: null,
    userId: null,
    email: null,
    status: SignInStatus.NOT_SIGNED_IN,
    jwtToken: null,
    error: null,
    loginTime: null,
    redirectPath: null
}

export const loginAsync = createAsyncThunk(
    'user/login',
    async (userPass: EmailPasswd) => {
        const response = ajaxPost('/api/login', { username: userPass.email, password: userPass.password })
        // The value we return becomes the `fulfilled` action payload
        return await response.then(r => r.data);
    }
);
//

export const loginWithGoogleAsync = createAsyncThunk(
    'user/login-with-google',
    async (googleProfile: GoogleProfile) => {
        const response = ajaxPost('/api/login-with-google', googleProfile)
        return await response.then(r => r.data);
    }
);
function clearLogin(state) {
    state.status = SignInStatus.NOT_SIGNED_IN;
    state.jwtToken = null;
    state.loginTime = null;
}

function updateToLoggedin(state, action) {
    const payload = action.payload;
    console.log(`login result payload:${JSON.stringify(payload)}`);
    state.userId = payload.userId as number;
    state.username = payload.username as string;
    state.email = payload.email as string;
    state.jwtToken = payload.jwt as string;
    state.loginTime = new Date().toISOString();
    state.status = SignInStatus.SIGNED_IN;
}
export const loginSlice = createSlice({
    name: 'signin',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        clearLogin: (state) => {
            state.status = SignInStatus.NOT_SIGNED_IN;
            state.jwtToken = null;
            state.loginTime = null;
        },
        clearLoginIfTimeout: (state) => {
            if (state.loginTime != null && getHoursDifference(new Date(state.loginTime), new Date()) > 4) {
                console.log(`login session timesout`);
                clearLogin(state);
            }
        },
        updateLoggedin: (state, action) => {
            updateToLoggedin(state, action);
        },
        updateLoginFailed: (state) => {
            state.status = SignInStatus.FAILED;
        },
        signOut: (state) => {
            clearLogin(state);
            console.log("User signed out");
        }
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder
            .addCase(loginAsync.pending, (state) => {
                state.status = SignInStatus.SINGNING_IN;
                console.log(`login in progress.`);
            })
            .addCase(loginAsync.fulfilled, (state, action) => {
                state.status = SignInStatus.SIGNED_IN;
                const payload = action.payload;

                console.log(`login result payload:${JSON.stringify(payload)}`);
                state.userId = payload.userId as number;
                state.username = payload.username as string;
                state.email = payload.email as string;

                state.jwtToken = payload.jwt as string;
                console.log(`login result token:${state.jwtToken}`);
                console.log(`login result email:${state.email}`);
                state.loginTime = new Date().toISOString();

            })
            .addCase(loginAsync.rejected, (state) => {
                clearLogin(state);
                console.log(`login failed.`);
                state.status = SignInStatus.FAILED;
            })

            .addCase(loginWithGoogleAsync.pending, (state) => {
                state.status = SignInStatus.SINGNING_IN;
            })
            .addCase(loginWithGoogleAsync.fulfilled, (state, action) => {
                state.status = SignInStatus.SIGNED_IN;
                const payload = action.payload;
                console.log(`google login result payload:${JSON.stringify(payload)}`);

                state.userId = payload.userId as number;
                state.username = payload.username as string;
                state.email = payload.email as string;

                state.jwtToken = payload.jwt as string;
                state.loginTime = new Date().toISOString();

            })
            .addCase(loginWithGoogleAsync.rejected, (state) => {
                state.status = SignInStatus.FAILED;
                console.log(`google login failed`);
                clearLogin(state);
            });

    }
}
);

export const { signOut, clearLoginIfTimeout, updateLoggedin, updateLoginFailed } = loginSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectJwtToken = (state: RootState) => state.signin.jwtToken;
export const selectSigninStatus = (state: RootState) => state.signin.status;
export const selectEmail = (state: RootState) => state.signin.email;


export default loginSlice.reducer;

