import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StationProductOrderDetailVO } from 'app/models/station_product_order_detail_vo';
import { StationProductOrderVO } from 'app/models/station_product_order_vo';
import { RootState } from 'app/store';
import { ajaxGetWithJWT } from 'service/http';



export interface YourOrdersState {
    orders: StationProductOrderVO[] | null,
    currentOrderDetail: StationProductOrderDetailVO | null,
    currentOrder: StationProductOrderVO | null,
    status: string | null
}

const initialState: YourOrdersState = {
    orders: [],
    currentOrderDetail: null,
    currentOrder: null,
    status: "unknown"
}

export const getAllOrdersAsync = createAsyncThunk(
    'orders/get-customer-orders',
    async (jwtToken: string) => {
        const response = await ajaxGetWithJWT("/api/get-customer-orders", jwtToken)
        return response.data;
    }
);

export interface OrderIdRequest {
    jwtToken: string;
    orderId: number;
}

export const getOrderByIdAsync = createAsyncThunk(
    'orders/get-customer-order-by-id',
    async (orderIdRequest: OrderIdRequest) => {
        const response = await ajaxGetWithJWT(`/api/get-customer-order-by-id/${orderIdRequest.orderId}`, orderIdRequest.jwtToken)
        return response.data;
    }
);


export const yourOrdersSlice = createSlice({
    name: 'yourorders',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        clearOrders: (state) => {
            state.orders = [];
        },
        setOrderDetail: (state, action: PayloadAction<number>) => {
            const orderDetailId = action.payload;
            const orderDetails =
                state.orders?.flatMap(order => {
                    order.orderDetails.map(d => {
                        if (d.stationProduerOrderDetailId === orderDetailId) {
                            state.currentOrder = order;
                        }
                        return d;
                    });
                    return order.orderDetails
                })
                    .filter(od => od.stationProduerOrderDetailId === orderDetailId);
            if (orderDetails && orderDetails!.length > 0) {
                state.currentOrderDetail = orderDetails![0];

            }
        },

        setOrder: (state, action: PayloadAction<StationProductOrderVO>) => {
            state.currentOrder = action.payload;
        }

    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder
            .addCase(getAllOrdersAsync.pending, (state) => {
                state.status = "fetching_your_orders";
                console.log(`login in progress.`);
            })
            .addCase(getAllOrdersAsync.fulfilled, (state, action) => {
                state.status = "order_retrieved";
                const payload = action.payload;
                console.log(`retrieve your orders result payload:${JSON.stringify(payload)}`);
                state.orders = payload as StationProductOrderVO[];
                console.log(`status:${state.status}`);
            })
            .addCase(getAllOrdersAsync.rejected, (state) => {
                state.status = "error_get_orders";
            })


            .addCase(getOrderByIdAsync.pending, (state) => {
                state.status = "fetching_your_orders";
                console.log(`login in progress.`);
            })
            .addCase(getOrderByIdAsync.fulfilled, (state, action) => {
                state.status = "order_retrieved";
                const payload = action.payload;
                console.log(`retrieve your order by Id result payload:${JSON.stringify(payload)}`);
                state.orders = payload as StationProductOrderVO[];
                console.log(`status:${state.status}`);
            })
            .addCase(getOrderByIdAsync.rejected, (state) => {
                state.status = "error_get_orders";
            });

    }
}
);

export const { setOrderDetail, setOrder, clearOrders } = yourOrdersSlice.actions;

export const selectCurrentOrder = (state: RootState) => state.yourorders.currentOrder;
export const selectCurrentOrderDetail = (state: RootState) => state.yourorders.currentOrderDetail;
export const selectOrders = (state: RootState) => state.yourorders.orders;
export const selectFetchOrderStatus = (state: RootState) => state.yourorders.status;


export default yourOrdersSlice.reducer;

