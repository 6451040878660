import React from "react";
import { useTranslation } from "react-i18next";
import "./signin.css";
export const OrLine = () => {
  const { t } = useTranslation();
  return (
    <div className="line-container">
      <div className="line" />
      <span>{t("or")}</span>
      <div className="line" />
    </div>
  );
};
