import { FaqVO } from "app/models/faq_vo";
import React, { useState } from "react";
import { AiOutlinePlusCircle, AiOutlineMinusCircle } from "react-icons/ai";
import "./faq.scss";
export interface FAQ {
  question: string;
  answer: string;
}
export const FAQItem = (faq: FaqVO) => {
  const [showAnswer, setShowAnswer] = useState<boolean>(false);
  const key = Math.floor(Math.random() * 10000);
  return (
    <div className="faq-item-container" key={key} id={key + ""}>
      <div className="faq-item-question-container">
        <div className="faq-item-question-icon">
          {showAnswer ? (
            <AiOutlineMinusCircle onClick={() => setShowAnswer(false)} />
          ) : (
            <AiOutlinePlusCircle onClick={() => setShowAnswer(true)} />
          )}
        </div>
        <div
          className="faq-item-question"
          onClick={() => setShowAnswer(!showAnswer)}
        >
          {faq.q}
        </div>
      </div>
      {showAnswer && (
        <div className="faq-item-answer-container">
          <span dangerouslySetInnerHTML={{ __html: faq.a }}></span>
        </div>
      )}
    </div>
  );
};
