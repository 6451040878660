import React, { Suspense, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import "./yourorder.scss";
import { useTranslation } from "react-i18next";

import { Navbar } from "../../components";
import Footer from "../../containers/footer/Footer";
import { StationProductOrderVO } from "app/models/station_product_order_vo";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { selectJwtToken } from "components/signin/sigin_slice";
import {
  clearOrders,
  getAllOrdersAsync,
  getOrderByIdAsync,
  selectFetchOrderStatus,
  selectOrders,
  setOrder,
} from "./yourorder_slice";

export const YourOrders = () => {
  const { t } = useTranslation();
  const jwtToken = useAppSelector(selectJwtToken);
  const orders = useAppSelector(selectOrders);
  let [searchParams, setSearchParams] = useSearchParams();
  const orderId = searchParams.get("orderId");
  const fetchOrderStatus = useAppSelector(selectFetchOrderStatus);

  const dispatch = useAppDispatch();
  const [orderMsg, setOrderMsg] = useState<String>();
  const navigate = useNavigate();

  useEffect(() => {
    if (jwtToken === null) {
      navigate("/signin");
      return;
    }
    dispatch(clearOrders());
    if (orderId) {
      let orderIdNum: number;
      try {
        orderIdNum = parseInt(orderId);
        console.log(`logId=${orderId}`);
        dispatch(
          getOrderByIdAsync({
            orderId: orderIdNum,
            jwtToken: jwtToken as string,
          })
        );
      } catch (e) {
        dispatch(getAllOrdersAsync(jwtToken));
      }
    } else {
      dispatch(getAllOrdersAsync(jwtToken));
    }
  }, []);

  const OrderItem = (orderItem: StationProductOrderVO) => {
    const viewOrder = () => {
      dispatch(setOrder(orderItem));
      navigate("/your-order-detail");
    };

    return (
      <div className="order-item-container" onClick={viewOrder}>
        <div className="order-item-title width30">{orderItem.orderId}</div>

        <div className="order-item-title-container">
          <div className="order-item-date">{orderItem.orderDateTxt}</div>
        </div>
        <div className="order-item-date width30">
          ${orderItem.totalAmount / 100.0}
        </div>

        <div className="order-item-link">
          <button onClick={() => viewOrder()}>
            <svg width="12" height="18" xmlns="http://www.w3.org/2000/svg">
              <path
                d="m2 1 8 8-8 8"
                stroke="#1D2026"
                stroke-width="3"
                fill="none"
                fillRule="evenodd"
              />
            </svg>
          </button>
        </div>
      </div>
    );
  };

  const orderHeader = (
    <div className="order-item-container underline">
      <div className="order-item-title width30 bold">Order ID</div>

      <div className="order-item-title-container">
        <div className="order-item-date bold">Order Date</div>
      </div>
      <div className="order-item-title-container">
        <div className="order-item-date bold">Amount</div>
      </div>

      <div className="order-item-link">&nbsp;</div>
    </div>
  );
  const yourOrderContainer = (
    <div className="your-orders-container">
      <h2>{t("your_orders")}</h2>
      {orderHeader}
      <div className="order-items-container">
        {(!orders || orders.length === 0) && <p>{t("your_have_no_order")}</p>}
        {orders &&
          orders.map(
            (order) => <OrderItem {...order} />
            /*const orderDetails = o.orderDetails;
            return orderDetails
              ? orderDetails.map((orderDetail) => {
                  return <OrderDetailItem {...orderDetail} />;
                })
              : [];*/
          )}
        {orderMsg && <div className="order-message">{orderMsg}</div>}
      </div>
    </div>
  );

  return (
    <>
      <div>
        <Suspense fallback={null}>
          <div>
            <Navbar />
            {yourOrderContainer}
          </div>

          <Footer />
        </Suspense>
      </div>
    </>
  );
};
