import { createAsyncThunk, createSlice, PayloadAction, current, createAction, nanoid } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { ajaxPost } from 'service/http';


export enum SignUpStatus {
    NOT_SIGNED_UP = 'not_signed_up',
    SUBMITTING = 'submitting',
    SIGNED_UP = 'signed_up',
    PENDING_ACTIVATION = 'pending_activation',
    ACTIVATED = 'activated',
    FAILED = 'failed'

}

export interface SignUpState {
    status: SignUpStatus,
    activationLink: string | null,
    email: string | null,
    error: string | null
}
export interface EmailPasswd {
    email: string,
    password: string
}

const initialState: SignUpState = {
    status: SignUpStatus.NOT_SIGNED_UP,
    email: null,
    activationLink: null,
    error: null
}

export const singupAsync = createAsyncThunk(
    'user/signup',
    async (emailPass: EmailPasswd, thunkAPI) => {
        const response = ajaxPost('/api/signup', { email: emailPass.email, password: emailPass.password })
        // The value we return becomes the `fulfilled` action payload
        return await response.then(r => r.data);
    }
);


// action creator
const actionIncrement = createAction<number | undefined>("counter/increment");

// action creator with prepare payload callback
const addTodo = createAction('todos/add', function prepare(text: string) {
    return {
        payload: {
            text,
            id: nanoid(),
            createdAt: new Date().toISOString(),
        },
    }
})
export const signupSlice = createSlice({
    name: 'signup',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        resetStatus: (state) => {
            // state logging
            console.log(`current state:${current(state)}`);
            state.status = SignUpStatus.NOT_SIGNED_UP;
        },
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder
            .addCase(singupAsync.pending, (state) => {
                state.status = SignUpStatus.SUBMITTING;
            })
            .addCase(singupAsync.fulfilled, (state, action) => {
                state.status = SignUpStatus.PENDING_ACTIVATION;

                const payload = action.payload;
                console.log(`payload:${JSON.stringify(payload)}`);
                state.email = payload.email as string;
                state.activationLink = payload.activationLink;

                // state.value += action.payload;
            })
            .addCase(singupAsync.rejected, (state) => {
                state.status = SignUpStatus.FAILED;
                //state.error =  action.payload;
            });
    }
}
);

export const { resetStatus } = signupSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
//export const selectJwtToken = (state: RootState) => state.signin.jwtToken;

export const selectSingupStatus = (state: RootState) => state.signup.status;

export default signupSlice.reducer;

