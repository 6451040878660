import React from "react";

import "./yourorder.scss";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import { PDFViewer } from "@react-pdf/renderer";
import { useAppSelector } from "app/hooks";
import { selectCurrentOrder } from "./yourorder_slice";
import { StationProductOrderDetailVO } from "app/models/station_product_order_detail_vo";
import { format } from "date-fns";
import { StationProductOrderVO } from "app/models/station_product_order_vo";
import { useTranslation } from "react-i18next";

// Create Document Component

Font.register({
  family: "Oswald",
  src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  canvas: {
    backgroundColor: "white",
    height: 500,
    width: 500,
  },
  title: {
    fontSize: 24,
    textAlign: "right",
  },
  textAlignRight: {
    textAlign: "right",
  },
  author: {
    fontSize: 12,
    textAlign: "center",
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
  },

  textBold: {
    fontSize: 14,
    fontWeight: "black",
    textAlign: "justify",
  },
  uppercase: {
    textTransform: "uppercase",
  },

  text: {
    fontSize: 14,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },
  font10: {
    fontSize: 10,
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  imgLogo: {
    width: 15,
    height: 15,
    marginRight: 2,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
    borderTop: "1px solid black",
    paddingTop: 5,
    marginHorizontal: 35,
  },
  pageHeader: {
    fontSize: 12,
    color: "grey",
    borderBottom: "1px solid black",
    paddingBottom: 5,
  },

  footer: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,

    color: "grey",
    borderTop: "1px solid black",
    paddingTop: 5,
    marginHorizontal: 35,
  },
  footerContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },

  totalContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  totalLine: {
    borderTop: "1px solid black",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  totalLineBlock: {
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-end",
  },
  marginRigth10: {
    marginRight: 10,
  },
  rowContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  boxBlue: {
    border: "1px solid black",
    width: "50%",
    margin: "1",
    padding: "5px",
    backgroundColor: "#DAF5FF",
  },
  bgBlue: {
    backgroundColor: "#DAF5FF",
  },
  underline: {
    borderBottom: "1px solid black",
  },
  boxBlank: {
    border: "1px solid black",
    margin: "1",
    padding: "5px",
    width: "50%",
  },
  marginBottom: {
    marginBottom: "10px",
  },
  gap15: {
    marginTop: 15,
  },
  paddingB: {
    paddingBottom: 10,
  },
  margin3: {
    margin: 3,
  },
  marginTB5: {
    marginBottom: 5,
    marginTop: 5,
  },
  taxInfo: {
    marginTop: "5px",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  flexColStart: {
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  flexRowSpace: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  flexRowStart: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  w50: {
    width: "50%",
  },
  w20: {
    width: "20%",
  },
  w10: {
    width: "10%",
  },
});
//<Image style={styles.image} src="/images/quijote1.jpg" />
const InvoiceItem = ({ orderDetail }) => {
  const d = orderDetail as StationProductOrderDetailVO;
  return (
    <View style={[styles.flexRowSpace, styles.marginTB5, styles.bgBlue]}>
      <Text style={[styles.text, styles.w50]}>{d.name}</Text>
      <Text style={[styles.text, styles.w20]}>${d.price / 100.0}</Text>
      <Text style={[styles.text, styles.w10]}>{d.count}</Text>
      <Text style={[styles.text, styles.w20, styles.textAlignRight]}>
        ${(d.price * d.count) / 100.0}
      </Text>
    </View>
  );
};
const MyDocument = ({ order, billAddress, invoiceId }) => {
  const o = order as StationProductOrderVO;
  const { t } = useTranslation();

  const orderDate = format(new Date(order.orderDate), "yyyy-MM-dd");
  console.log(`billing address:${billAddress}`);
  return (
    <Document>
      <Page size="LETTER" style={styles.body}>
        <View style={styles.pageHeader}>
          <View style={styles.flexRowSpace}>
            <View style={styles.flexRowStart}>
              <Image style={styles.imgLogo} src="/FaviconLogo.jpg" />
              <Text style={[styles.text, styles.font10]}>{t("everpx")}</Text>
            </View>

            <Text style={[styles.text, styles.font10]}>
              {t("invoice_cap")} #: {invoiceId}
            </Text>
          </View>
        </View>
        <View style={styles.gap15} />
        <Text style={[styles.title, styles.marginBottom]}>
          {t("invoice_cap")}
        </Text>
        <View style={styles.rowContainer}>
          <View>
            <Text style={[styles.textBold, styles.margin3]}>
              {t("bill_to").toUpperCase()}:
            </Text>
            <Text style={[styles.textBold, styles.uppercase, styles.margin3]}>
              {billAddress}
            </Text>
          </View>
          <View style={styles.boxBlue}>
            <Text style={[styles.text, styles.margin3]}>
              {t("invoice_date")}: {o && orderDate}
            </Text>
            <Text style={[styles.text, styles.margin3]}>
              {t("invoice_cap")} #: {invoiceId}
            </Text>

            <Text style={[styles.text, styles.margin3]}>
              {t("order_date")}: {o && orderDate}
            </Text>
            <Text style={[styles.text, styles.margin3]}>
              {t("total_payable")}: ${o && o.totalAmount / 100.0}
            </Text>
          </View>
        </View>

        <View style={styles.taxInfo}>
          <View style={styles.boxBlank}>
            <Text style={[styles.text, styles.margin3]}>
              GST/HST Remitted By: Everpx Inc.
            </Text>
            <Text style={[styles.text, styles.margin3]}>
              GST/HST #: GA4542233323
            </Text>
          </View>
        </View>

        <View style={styles.gap15} />
        <View style={styles.gap15} />
        <View>
          <Text style={[styles.subtitle, styles.underline, styles.paddingB]}>
            {t("invoice_details")}
          </Text>
        </View>

        <View style={[styles.flexRowSpace, styles.marginTB5, styles.bgBlue]}>
          <Text style={[styles.textBold, styles.w50]}>{t("item_name")}</Text>
          <Text style={[styles.textBold, styles.w20]}>{t("unit_price")}</Text>
          <Text style={[styles.textBold, styles.w10]}>{t("count_cap")}</Text>
          <Text style={[styles.textBold, styles.w20, styles.textAlignRight]}>
            {t("item_subtotal")}
          </Text>
        </View>
        {o.orderDetails.map((d) => (
          <InvoiceItem orderDetail={d} />
        ))}

        <View style={styles.totalContainer}>
          <View style={styles.totalLine}>
            <View style={[styles.totalLineBlock, styles.marginRigth10]}>
              <Text style={styles.text}>{t("total")}:</Text>
            </View>
            <View style={styles.totalLineBlock}>
              <Text style={styles.text}>${o.totalAmount / 100.0}</Text>
            </View>
          </View>
        </View>

        <View style={[styles.footer]}>
          <View style={styles.footerContainer}>
            <Text style={[styles.text, styles.font10]}>Everpx Inc.</Text>
            <Text
              style={[styles.text, styles.font10]}
              render={({ pageNumber, totalPages }) =>
                `${pageNumber} / ${totalPages}`
              }
            />
          </View>
        </View>
      </Page>
    </Document>
  );
  /**
   * 
        <Canvas
          style={styles.canvas}
          paint={
            (painterObject) =>
              painterObject
                .save()
                .moveTo(100, 100) //move to position 100,100
                .lineTo(300, 100) //draw a line till 300, 100
                .lineTo(300, 300) //draw another line till 300,300
                .fill("red") //when the diagram is drawn, set the background color to pink
          }
        />
   */
};

export const Invoice = () => {
  const order = useAppSelector(selectCurrentOrder) as StationProductOrderVO;
  const { t } = useTranslation();
  const loading_invoice = t("loading_invoice");
  const download = t("download");
  const hasInvoice = order.orderInvoices && order.orderInvoices.length > 0;
  const invoiceId = hasInvoice ? order.orderInvoices![0].invoiceId : null;

  const billingAddress = hasInvoice
    ? order.orderInvoices![0].billingAddress
    : null;
  const fileName = `everpx-station-invoice-${invoiceId}.pdf`;
  return (
    <div>
      <PDFDownloadLink
        document={
          <MyDocument
            order={order}
            billAddress={billingAddress}
            invoiceId={invoiceId}
          />
        }
        fileName={fileName}
      >
        {({ blob, url, loading, error }) =>
          //loading ? { loading_invoice } : { download }
          loading ? (
            <div className="invoice-download">{loading_invoice}</div>
          ) : (
            <div className="invoice-download">{download}</div>
          )
        }
      </PDFDownloadLink>

      <PDFViewer
        width={"100%"}
        height={"800px"}
        className="invoice-pdf"
        showToolbar={false}
      >
        <MyDocument
          order={order}
          billAddress={billingAddress}
          invoiceId={invoiceId}
        />
      </PDFViewer>
    </div>
  );
};
