import React from "react";
import { useNavigate } from "react-router-dom";
import "./signin.css";
import logo from "../../assets/everpx_logo.jpg";
import axios, { AxiosResponse } from "axios";
import { GoogleLogin } from "react-google-login";
import { useTranslation } from "react-i18next";
import { useForm, SubmitHandler } from "react-hook-form";

//import { INITIAL_STATE, LoginActinKind, loginReducer } from 'service/login_reducer';
import { resetStatus, SignUpStatus, singupAsync } from "./signup_slice";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { Link } from "react-router-dom";

type SignFormProps = {
  formFieldsEle: JSX.Element;
};
const SignForm = ({ formFieldsEle }: SignFormProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const everpx = t("everpx");

  return (
    <div id="backpage" className="sigin-container">
      <div className="sigin-form">
        <div className="row">
          <div>
            <img
              src={logo}
              className="row-logo"
              width="100px"
              height="100px"
              alt="logo"
              onClick={() => navigate("/")}
            />
          </div>
          <h2>{everpx}</h2>
        </div>
        {formFieldsEle}
      </div>
    </div>
  );
};
//oauth secret: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
export default SignForm;
