import React, { useEffect, useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { SignResponse } from "./sign_response";
import "./signin.css";

export const ActivationExpirePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const msgActivationExpired = t("activation_expired");
  const msgGotoSignupPage = t("goto_signup");

  const gotoSignupPage = () => navigate("/signup");

  const activationSuccess: JSX.Element = (
    <div>
      <div className="warn">
        <p>{msgActivationExpired}</p>
      </div>
      <div className="spacer" />
      <button type="button" className="btn-warn" onClick={gotoSignupPage}>
        {msgGotoSignupPage}
      </button>
    </div>
  );
  return <SignResponse messageBox={activationSuccess} />;
};
