import React from "react";
import "./sandbox.css";
export const Sandbox = () => {
  return (
    <div className="flex-container">
      <div className="item">item1</div>
      <div className="item">item2</div>
      <div className="item">item3</div>
      <div className="item">item1</div>
      <div className="item">item2</div>
      <div className="item">item3</div>
      <div className="item">item1</div>
      <div className="item">item2</div>
      <div className="item">item3</div>
    </div>
  );
};
