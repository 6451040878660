import { configureStore, ThunkAction, Action, getDefaultMiddleware, combineReducers, Reducer, AnyAction } from '@reduxjs/toolkit'
import loginReducer from '../components/signin/sigin_slice';
import signupReducer from '../components/signin/signup_slice';
import productRedcuer from '../components/product-detail/product_slice';

import yourordersReducer from '../components/your_orders/yourorder_slice';

import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';



const persistConfig = {
    key: 'everpx_root',
    storage,
};
const combinedReducer = combineReducers({
    signin: loginReducer,
    signup: signupReducer,
    product: productRedcuer,
    yourorders: yourordersReducer
});

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {

    const appVer = process.env.REACT_APP_VERSION;
    const appVerInLocalStorage = localStorage.getItem('REACT_APP_VERSION');

    if (appVerInLocalStorage == null && appVer != null) {
        localStorage.setItem('REACT_APP_VERSION', appVer ? appVer : '');
    } else if (appVerInLocalStorage != null && appVer != null && appVer !== appVerInLocalStorage) {
        // app version changed, clear out all application state
        console.log(`app version changed:${appVerInLocalStorage} -> ${appVer}`)
        localStorage.setItem('REACT_APP_VERSION', appVer ? appVer : '');

        state = {} as RootState;
    }

    /**
    if (action.type === 'example/clearResults') {

        // this applies to all keys defined in persistConfig(s)
        storage.removeItem('persist:root')

        state = {} as RootState
    }
     */
    return combinedReducer(state, action)
}
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
    }),
});
export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
