import Navbar from "components/navbar/Navbar";
import { Footer } from "containers";
import React, { Suspense } from "react";
import "./legal.scss";
export const TermsOfService = () => {
  const termsOfService = (
    <div className="legal">
      <h2> Terms of Service</h2>
      <p>
        Our software, be it the Everpx Station or the Android or iOS apps, are
        designed to not sent any user data to us. The License module in Everpx
        Station sends Everpx Station version, hardware information such as CPU,
        RAM OS name to Everpx Server and License information to validate the
        license.
      </p>
      <h3>Software Provided By Everpx</h3>
      <p>
        Everpx provides the everpx station software to let you self host a photo
        server for evperx mobile applications. Everpx mobile is used to upload
        phone's photos and videos to everpx station and manage the photos.
        <br />
        There is no legal entitlement for using the everpx community edition.
        Everpx has the right to stop or deny the using the software without
        giving any reasons.
        <br />
        Everpx License is a life-time license, which means once it is activated,
        it will be functioning forerver.
      </p>
      <h3>Service Provided By Everpx</h3>
      <p>
        Everpx provides paid technical support for the everpx station and mobile
        application, the support is based on trying best effort basis.
        <br /> The technical support is limited to ensuring the everpx software
        function properly.
        <br /> Everpx is not responsible for any data loss or corruption of the
        data uploaded to the everpx station server; Everpx station is designed
        to try its best effort to maintain the data integrity by storing the
        data to multiple hot and cold disks;
      </p>
    </div>
  );
  return (
    <div>
      <Suspense fallback={null}>
        <div>
          <Navbar />
          {termsOfService}
        </div>

        <Footer />
      </Suspense>
    </div>
  );
};
