import React from "react";
import "./download.css";
import { useTranslation } from "react-i18next";


const Plan = ({ title, features, price, currency, priceFrequency,buyDownload } ) => {
  const { t } = useTranslation();
  
  return (
    <div className="everpx__plans-half">
      
      <ul className="everpx__plans-plan  everpx__plans-white  everpx__plans-li">
        <li className="everpx__plans-blue everpx__plans-large everpx__plans-padding-32 everpx__plans-li">
          {title}
        </li>

        {features.map((f, i) => (
          <li className="everpx__plans-padding-16 everpx__plans-li">{f}</li>
        ))}

        <li className="everpx__plans-li">
          <div className="everpx__plans-padding-24">
            <p className="everpx__plans-xlarge">
              {currency} {price}
            </p>
            <span className="everpx__plans-opacity">{priceFrequency}</span>
          </div>
        </li>

        <li className="everpx__plans-light-grey everpx__plans-padding-24">
          
        </li>
      </ul>
      
      <button className="everpx__plans-button ">
            {buyDownload === "d"?t("download"):t("buy")}
      </button>
        
    </div>
  );
};

const EverpxStatonAdmin = () => {
  const { t } = useTranslation();

  
 

  return (
        
    <div className="everpx__plans section__margin" id="downloads">
       <div className="everpx__whatiseverpx-heading">
                <h1 className="gradient__text">{t('downloadStationAdmin')}</h1>
       </div>

      <div className="everpx__plans-content">
       
        <img src="images/applestore.svg" />
       <img src="images/googleplay.png" className="appstore"/>
        
      </div>
    </div>
    
  );
};

export default EverpxStatonAdmin;
