import React ,{ useState,useEffect,useRef } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { Navigate, useNavigate } from "react-router-dom";
import {useTranslation} from 'react-i18next';
import './navbar.scss';

import {RiMenu3Line,RiCloseLine,RiAccountPinCircleLine} from 'react-icons/ri'
import logo from '../../assets/everpx_logo.jpg';
import {  useAppSelector } from 'app/hooks';
import { selectEmail, selectSigninStatus, SignInStatus, signOut } from 'components/signin/sigin_slice';
import { useDispatch } from 'react-redux';
import { CartButton } from './CartButton';

function useOnClickOutside(ref, handler) {
  useEffect(
    () => {
      const listener = (event) => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        handler(event);
      };
      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);
      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler]
  );
}


//<p><a href = "/#whatiseverpx" >{t('what_is_everpx')} </a></p>
const Menu = () =>{
    const {t} = useTranslation();
    return (<>
        <p><Link to="/#whatiseverpx">{t('what_is_everpx')}</Link></p>
        <p><Link to="/#downloads">{t('download')}</Link></p>
        <p><Link to="/doc">{t('documentation')}</Link></p>
        <p><Link to="/faq">{t('faq')}</Link></p>
        <p> <Link to="/about">{t('about')}</Link></p>
    </>)
}
//<button type="button">{t('sign_up')}</button>
const SignIn = () => {
    const {t} = useTranslation();
    return (
       
      <p><Link to="/signin">{t('sign_in')}</Link></p>
     
    )
};
const Signup = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const handleSignup = ()=> navigate("/signup");
     return (
       <p> 
        <button type="button" className="signupButton" onClick={handleSignup}>{t('sign_up')}</button>
     </p>
     );
   
};
const Sign = () => {
    const {t} = useTranslation();
     const navigate = useNavigate();
     const handleSignup = ()=> navigate("/signup");
    return (<div className="everpx__navbar-sign">
        <p><Link to="/signin">{t('sign_in')}</Link></p>
        <p><button type="button" className="signupButton" onClick={handleSignup}>{t('sign_up')}</button></p>
    </div>);
};
const Account = () =>{
     const {t} = useTranslation();
     const navigate = useNavigate();
     const userEmail = useAppSelector(selectEmail);
     console.log(`user email:${userEmail}`);
    const [toggleAccountMenu, setToggleAccountMenu] = useState(false);
    const dispatch = useDispatch();
    const handleSignout= ()=>{
        dispatch(signOut());
        console.log('user clicked signout');
        setToggleAccountMenu(!toggleAccountMenu);
        navigate("/");
        
    }
    const handleYourOrders=()=>{
        navigate("/your-orders");
    };
    const handleYourAccount =() =>{
        navigate("/your-account");
    }

    const handleYourBillAddress =() =>{
        navigate("/your-billaddress");
    }
    
    const ref = useRef();
    useOnClickOutside(ref, () => setToggleAccountMenu(false));
    //
   //  <p><a href="#" onClick={handleYourAccount}>{t("your_account")}</a></p>
                           
  return (
        <div className="account">
          <RiAccountPinCircleLine color="hsl(220, 14%, 75%)" size={27} onClick={()=>setToggleAccountMenu(!toggleAccountMenu)}/>
                { toggleAccountMenu && (
                    
                    <div ref={ref} className="everpx__navbar-menu_container scale-up-center">
                         <RiCloseLine color="#000" size={27} onClick={()=> setToggleAccountMenu(false)}/>
                        <div className="everpx__navbar-menu_container-links">
                            
                            <p><a href="#" onClick={handleYourBillAddress}>{t("your_billaddress_cap")}</a></p>
                            <p><a href="#" onClick={handleYourOrders}>{t("your_orders")}</a></p>
                            <p><a href="#" onClick={handleSignout}>{t("sign_out")}</a></p>
                           
                        </div>
                        <div className="everpx__navbar-menu_container-links-sign">
                            <SignIn/>
                        </div>
                    </div>
                )}
        </div>
  );
};

const Navbar = () => {
    const [toggleMenu, setToggleMenu] = useState(false);
    const navigate = useNavigate();
     
     const siginStatus = useAppSelector(selectSigninStatus);
     const ref = useRef();
      useOnClickOutside(ref, () => setToggleMenu(false));
    return (
        <div className="everpx__navbar">
            <div className="everpx__navbar-links">
                <div className="everpx__navbar-links_logo_container">
                    <div className="everpx__navbar-links_logo">
                        <img src={logo} alt='logo' onClick={()=> navigate("/")}/>
                    </div>
                     <div className="everpx__navbar-logotext">Everpx</div>
                </div>
               
                <div className="everpx__navbar-links_container">
                    <Menu/>
                </div>
            </div>

          
            {siginStatus == SignInStatus.SIGNED_IN?
            <div className="everpx__navbar-account-cart"><Account /> </div>:
             (<div className="everpx__navbar-sign"><Sign/></div>)}
            
            <CartButton/>
            <div className="everpx__navbar-menu">
               
                <RiMenu3Line color="#000" size={27} onClick={()=> setToggleMenu(true)}/>
                
                {toggleMenu && (
                    <div ref={ref} className="everpx__navbar-menu_container scale-up-center">
                        <RiCloseLine color="#000" size={27} onClick={()=> setToggleMenu(false)}/>
                        <div className="everpx__navbar-menu_container-links">
                            <Menu/>
                        </div>
                        <div className="everpx__navbar-menu_container-links-sign">
                            {siginStatus == SignInStatus.SIGNED_IN?(<></>):(<><SignIn/><Signup/></>)}
                            
                        </div>
                     </div>
                )}
            </div>

           
            
        </div>
    )
}

/*
{toggleMenu
                ?<RiCloseLine color="#000" size={27} onClick={()=> setToggleMenu(false)}/>
                :<RiMenu3Line color="#000" size={27} onClick={()=> setToggleMenu(true)}/>
                }
*/
export default Navbar

/*
 <div className="everpx__navbar-sign">
            <Sign/>
            </div>
*/