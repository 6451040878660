import React from "react";
import "./signin.css";
import logo from "../../assets/everpx_logo.jpg";

type ResponseProps = {
  messageBox: JSX.Element;
};
export const SignResponse = ({ messageBox }: ResponseProps) => {
  return (
    <div id="backpage" className="sigin-container">
      <div className="sigin-form">
        <div className="row">
          <div>
            <img
              src={logo}
              className="row-logo"
              width="100px"
              height="100px"
              alt="logo"
            />
          </div>
          <h2>Everpx</h2>
        </div>
        <div className="spacer"></div>
        <div className="messge-container">{messageBox}</div>
      </div>
    </div>
  );
};
