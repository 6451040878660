import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import "./signin.css";
import logo from "../../assets/everpx_logo.jpg";
import { useTranslation } from "react-i18next";
import { useForm, SubmitHandler } from "react-hook-form";

import { resetStatus, SignUpStatus, singupAsync } from "./signup_slice";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { Link } from "react-router-dom";
import SignForm from "./Sign_form";
import GoogleSignin from "./google_signin";
import { OrLine } from "./or_line";

type EmailPassword = {
  email: string;
  password: string;
};

const Signup = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const signupStatus = useAppSelector((state) => state.signup.status);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<EmailPassword>();
  const onSubmit: SubmitHandler<EmailPassword> = (data) => {
    console.log(`submitted data:${JSON.stringify(data)}`);
    dispatch(singupAsync(data));
  };

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailPasswordValid, setEmailPasswordValid] = useState(false);

  console.log(`signupStatus:${signupStatus}`);

  const goHome = () => {
    dispatch(resetStatus());
    navigate("/");
  };

  const SuccessSignup = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const successSignup = t("successSignup");
    const goHomePage = t("go_home_page");
    return (
      <div>
        <div className="success">
          <p>{successSignup}</p>
        </div>
        <button type="button" className="btn-success" onClick={goHome}>
          {goHomePage}
        </button>
      </div>
    );
  };

  const textPrivacy = t("agree_terms");
  const emailRequired = t("emailIsRequired");
  const invalidEmailAddress = t("invalidEmailAddress");
  const passwordIsRequired = t("passwordIsRequired");
  const passwordMinLen = t("passwordMinLen", { minLen: 10 });
  const signup = t("sign_up");
  const signupFields = (
    <div className="fields-container">
      <div className="horizontalLine"></div>
      <form onSubmit={handleSubmit(onSubmit)}>
        {signupStatus === SignUpStatus.FAILED && (
          <p className="error-message">{invalidEmailAddress}</p>
        )}
        <input
          type="text"
          key="emailkey"
          placeholder={t("enter_email")}
          {...register("email", {
            required: true,
            pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
          })}
        />
        {errors.email?.type === "required" && (
          <p className="error-message">{emailRequired}</p>
        )}
        {errors.email?.type === "pattern" && (
          <p className="error-message">{invalidEmailAddress}</p>
        )}
        <input
          type="password"
          key="passwordkey"
          placeholder={t("enter_password")}
          {...register("password", { required: true, minLength: 10 })}
        ></input>
        {errors.password?.type === "required" && (
          <p className="error-message">{passwordIsRequired}</p>
        )}
        {errors.password?.type === "minLength" && (
          <p className="error-message">{passwordMinLen}</p>
        )}
        <button type="submit" className="btn-login">
          {signup}
        </button>
      </form>

      <div className="horizontalText">
        {textPrivacy} &nbsp;
        <Link to="/term-of-service">{t("termsConditions")}</Link>
        &nbsp;{t("and")}&nbsp;
        <Link to="/privacy-policy">{t("privacyPolicy")}</Link>
      </div>

      <div className="passcontainer">
        <span className="signup">{t("already_have_account")}</span>
        <span className="forgetpass">
          <a href="/signin">{t("sign_in")}</a>
        </span>
      </div>

      <div className="horizontalText"></div>
      <div className="horizontalLine"></div>
      <OrLine />
      <GoogleSignin />
    </div>
  );
  let returnPage;
  if (signupStatus === SignUpStatus.FAILED) {
    returnPage = <Navigate to="/signup-registered" />;
  } else if (signupStatus === SignUpStatus.PENDING_ACTIVATION) {
    returnPage = <Navigate to="/signup-success" />;
  } else {
    returnPage = <SignForm formFieldsEle={signupFields} />;
  }
  return returnPage;
};
//oauth secret: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
export default Signup;
