import React from 'react'
import './whatiseverpx.css';
import Feature from '../../components/feature/Feature'
import {useTranslation} from 'react-i18next';
const Whatiseverpx = () => {
    const {t} = useTranslation();
    return (
        <div className="everpx__whatiseverpx section__margin" id="whatiseverpx">
            <div className="everpx__whatiseverpx-feature">
                <Feature title={t('what_is_everpx')} text={t('what_is_everpx_content')}/>
            </div>

            <div className="everpx__whatiseverpx-heading">
                <h1 className="gradient__text">{t('store_life')}</h1>
            </div>

            <div className="everpx__whatiseverpx-container">
                <Feature title={t('feature_private')} text={t('feature_private_content')} />
                <Feature title={t('feature_safe')} text={t('feature_safe_content')}/>
                <Feature title={t('feature_easy_sync')} text={t('feature_easy_sync_content')}/>
                <Feature title={t('feature_unlimited')} text={t('feature_unlimited_content')}/>
                <Feature title={t('feature_low_cost')} text={t('feature_low_cost_content')}/>
                <Feature title={t('feature_photo_organizer')} text={t('feature_photo_organizer_content')}/>
                <Feature title={t('feature_light_nas')} text={t('feature_light_nas_content')}/>
            </div>
       </div>
    )
}

export default Whatiseverpx
