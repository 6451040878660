import React, { Suspense,useEffect } from 'react';
import { Routes, Route,Navigate} from "react-router-dom";
import {Header, Footer,Whatiseverpx,Downloads} from './containers';
import {Navbar,Signin,Signup} from './components';
import './i18n'

import './App.css';
import './index.css';
import About from './containers/about/About';
import { ActivationSuccessPage } from 'components/signin/activation_success';
import { ActivationExpirePage } from 'components/signin/activation_expired';
import { ForgetPasswordPage } from 'components/signin/forget_password';
import { ForgetPasswordSuccessPage } from 'components/signin/forgetpassword_success';
import { ServerErrorPage } from 'components/signin/server_error';
import { ResetPasswordPage } from 'components/signin/reset_password';
import { ResetPasswordSuccessPage } from 'components/signin/restpassword_success';
import { ResetpasswordFailurePage } from 'components/signin/resetpassword_failure';
import { SignupSuccessPage } from 'components/signin/signup_success';
import EverpxStatonAdmin from 'containers/downloads/EverpxStationMobile';
import { Sandbox } from 'components/sandbox/Sandbox';
import ProductInfo from 'containers/product_info/ProductInfo';
import { CartSummary } from 'containers/product_info/CartSummary';
import { Checkout } from 'components/checkout/Checkout';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import { TermsOfService } from 'components/legal/TermsOfService';
import { PrivacyPolicy } from 'components/legal/PrivacyPolicy';
import { SignupAlreadyRegisteredPage } from 'components/signin/signup_already_registered';
import { useAppDispatch } from 'app/hooks';
import { clearLoginIfTimeout } from 'components/signin/sigin_slice';
import { DownloadPage } from 'containers/downloads/download_page';
import { PaymentSuccessPage } from 'components/checkout/checkout_success';
import { NotFoundPage } from 'components/navbar/PageNotFound';
import { YourOrders } from 'components/your_orders/YourOrder';
import { OrderDetails } from 'components/your_orders/OrderDetail';
import { YourAccount } from 'components/your-account/YourAccount';
import { YourBillAddress } from 'components/your-account/YourBillAddress';
import { Faq } from 'components/faq/Faq';
import { Faq2 } from 'components/faq/faq2';

const Home = () =>{
    const dispatch = useAppDispatch();

    useEffect(
    () => {
        dispatch(clearLoginIfTimeout());
    },[]);
    return (
        <div>
            <Suspense fallback={null}>
            <div >
                <Navbar />  
                <Header />  
            </div>
            <Whatiseverpx />
            <Downloads />
            <EverpxStatonAdmin />
            <Footer />
           </Suspense>
        </div>
    );

}

const Doc = () => {
    window.location.href="/doc/index.html";
 return (
        <>
        <Navigate to = "/doc/index.html" />
        </>
 );
};

const App = () => {
    
    return (
     
       
        <Suspense fallback={<div>Loading...</div>}>
         <PayPalScriptProvider options={{ "client-id": "AYb-7KOFjnk89PA8MuVUBf9UbnGRlUyAl4uNqBqHZTbNwjYZVw2qlUQ36TPZ_gwXTq3BS6Y5oqRI8UBM" }}>

            <div className='App'>
                <Routes>
                    <Route path="/" element={ <Home/> } />
                    <Route path="/signin" element={ <Signin/> } />
                    <Route path="/activation-success" element={<ActivationSuccessPage/>} />
                    <Route path="/activation-expired" element={<ActivationExpirePage/>} />
                    <Route path="/forget-password" element={<ForgetPasswordPage/>}/>
                     <Route path="/forgetpassword-success" element={<ForgetPasswordSuccessPage/>}/>
                    
                    <Route path="/forget-password-success" element={<ForgetPasswordSuccessPage/>} />
                    <Route path="/reset-password/:resetpasswordCode" element={<ResetPasswordPage/>}/>
                    <Route path="/reset-password-success" element={<ResetPasswordSuccessPage/>}/>
                    <Route path="/reset-password-failure" element={<ResetpasswordFailurePage/>} />
                    <Route path="/server-error" element={<ServerErrorPage/>} />
                    <Route path="/sandbox" element= {<Sandbox />}/>
                    <Route path="/productInfo/:productId" element = {<ProductInfo />} />
                    <Route path="/cartItemList" element = {<CartSummary />} />
                    <Route path="/checkout" element = {<Checkout/>} />
                    <Route path="/term-of-service" element = {<TermsOfService/>} />
                    <Route path="/privacy-policy" element = {<PrivacyPolicy/>} />
                     <Route path="/download" element = {<DownloadPage/>} />
                     <Route path="/checkout-success" element={ <PaymentSuccessPage/> } />
                    
                    
                     <Route path="/signup" element={ <Signup/> } />
                     <Route path="/signup-success" element = {<SignupSuccessPage />} />
                     <Route path="/signup-registered" element = {<SignupAlreadyRegisteredPage/>}/>
                    <Route path="/doc" element={ <Doc/> } />
                    <Route path="/about" element={ <About/> } />
                    <Route path="/your-orders" element={ <YourOrders/> } />

                    <Route path="/your-order-detail" element={ <OrderDetails/> } />
                    <Route path="/your-account" element={ <YourAccount/> } />
                    <Route path="/your-billaddress" element={ <YourBillAddress/> } />
                    <Route path="/faq" element={ <Faq /> } />
                    
                    
                    <Route path="*" element={ <NotFoundPage/> } />
                    
                    
                </Routes>
            </div>
        </PayPalScriptProvider>
    </Suspense>
     
    );
}

export default App
