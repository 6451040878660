import axios, { AxiosRequestHeaders } from "axios";
export const apiKey = "";

const config = {
    baseUrl: "http://localhost:3000"
}
export const http = axios.create({
    //  baseURL: config.baseUrl,
});

export const ajaxGetCB = (url: string, onSuccess, onError) => http.get(url)
    .then(res => onSuccess(res))
    .catch(err => onError(err))

export const ajaxPostCB = (url: string, obj: any, onSuccess, onError) => http.post(url, obj)
    .then(res => onSuccess(res))
    .catch(err => onError(err))

export const ajaxPost = (url: string, obj: any, headers: AxiosRequestHeaders | null = null) => {
    const h = headers;
    if (h !== null) {
        console.log(`post with headers..`);
        return http.post(url, obj, { headers: h });
    } else {
        console.log(`post with NO headers..`);
        return http.post(url, obj);
    }
}

export const ajaxPostWithJWT = (url: string, obj: any, jwt: string) => {
    const headers = {
        "authorization": `Bearer ${jwt}`
    };
    console.log(`post with jwt:${jwt}`);
    return ajaxPost(url, obj, headers);
}

export const ajaxGetWithJWT = (url: string, jwt: string) => {
    const headers = {
        "authorization": `Bearer ${jwt}`
    };
    console.log(`post with jwt:${jwt}`);
    return ajaxGet(url, headers);
}
export const ajaxGet = (url: string, headers: AxiosRequestHeaders | null = null) => {
    if (headers !== null) {
        console.log(`get with headers..`);
        return http.get(url, { headers: headers });
    } else {
        console.log(`get with NO headers..`);
        return http.get(url);
    }

} 