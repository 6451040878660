import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { ajaxGet, ajaxPost, ajaxPostWithJWT } from 'service/http';
import { roundTo } from 'service/utils';
import { Product, ProductImage, CartItem } from './product';


export class ProductRepo {
    static productImages: ProductImage[] = [1, 2, 3, 4].map((index) => {
        return {
            id: index,
            img: `/images/demo/image-product-${index}.jpg`,
            imgThumbnail: `/images/demo/image-product-${index}-thumbnail.jpg`,
        };
    });

    static standardImages: ProductImage[] = [
        {
            id: 1,
            img: `/images/station/admin_local_storage.png`,
            imgThumbnail: `/images/station/admin_local_storage.png`,
        },
        {
            id: 2,
            img: `/images/station/user_list.png`,
            imgThumbnail: `/images/station/user_list.png`,
        },
        {
            id: 3,
            img: `/images/station/albums.png`,
            imgThumbnail: `/images/station/albums.png`,
        },
        {
            id: 4,
            img: `/images/station/gallery.png`,
            imgThumbnail: `/images/station/gallery.png`,
        },
    ];
    static premiumImages: ProductImage[] = [
        {
            id: 1,
            img: `/images/station/admin_local_storage.png`,
            imgThumbnail: `/images/station/admin_local_storage.png`,
        },
        {
            id: 2,
            img: `/images/station/remote_storage_list.png`,
            imgThumbnail: `/images/station/remote_storage_list.png`,
        },
        {
            id: 3,
            img: `/images/station/albums.png`,
            imgThumbnail: `/images/station/albums.png`,
        },
        {
            id: 4,
            img: `/images/station/gallery.png`,
            imgThumbnail: `/images/station/gallery.png`,
        },
    ];
    static standard: Product = {
        productId: 1,
        name: "product_standard_name",
        title: "product_standard_title",
        description: "product_standard_desc",
        price: 4999,
        currency: "USD",
        promotionPrice: 3999,
        features: ["feature_no_subscription", "feature_no_installation", "feature_file_encryption", "feature_storage_disks_unlimited", "feature_massive_import", "feature_one_year_support"],
        images: ProductRepo.standardImages
    }

    static premium: Product = {
        productId: 2,
        name: "product_premium_name",
        title: "product_premium_title",
        description: "product_premium_desc",
        price: 7999,
        currency: "USD",
        promotionPrice: 6999,
        features: ["feature_no_subscription", "feature_no_installation", "feature_file_encryption", "feature_storage_disks_unlimited", "feature_remote_backup", "feature_cloud_backup", "feature_one_year_support"],
        images: ProductRepo.premiumImages
    }

    public static getProductById(id: number): Product {
        if (id === 1) {
            return ProductRepo.standard;
        } else {
            return ProductRepo.premium;
        }

    }
}


class Cart {
    private cartItems: CartItem[];
    constructor(cartItems: CartItem[]) {
        this.cartItems = cartItems;
    }

    public getTotalItems(): number {
        return this.cartItems.map(item => item.count).reduce((prevValue, value, index) => prevValue + value, 0);
    }
    public getAmount(): number {
        const amountInCents = this.cartItems.map(item => item.count * item.product.promotionPrice).reduce((prevValue, value, index) => prevValue + value, 0);
        return roundTo(amountInCents / 100.0, 2);
    }
    public addCartItem(cartItem: CartItem) {
        this.addProduct(cartItem.product, cartItem.count);
    }
    public decreaseCartItem(cartItem: CartItem) {
        this.addProduct(cartItem.product, -cartItem.count);
    }
    public updateCartItem(cartItem: CartItem) {
        this.setProduct(cartItem.product, cartItem.count);
    }
    public addProduct(product: Product, count: number) {
        const existingCartItem = this.findCartItem(product);
        if (existingCartItem) {
            const result = existingCartItem.count + count;
            existingCartItem.count = result > 0 ? result : 0;
        } else if (count > 0) {
            this.cartItems.push({ product, count });
        }

    }

    private setProduct(product: Product, count: number) {
        const existingCartItem = this.findCartItem(product);
        if (existingCartItem) {
            if (count === 0) {
                const index = this.findCartItemIndex(product);
                this.cartItems.splice(index as number, 1);
            } else {
                existingCartItem.count = existingCartItem.count;
            }

        } else if (count !== 0) {
            this.cartItems.push({ product, count });
        }

    }

    private findCartItem(product: Product): CartItem | undefined {
        return this.cartItems.find((item, _) => item.product.productId === product.productId);
    }

    private findCartItemIndex(product: Product): number | undefined {
        for (let i = 0; i < this.cartItems.length; i++) {
            if (this.cartItems[i].product.productId === product.productId) {
                return i;
            }
        }
        return undefined;

    }


}
interface CartState {
    total: number;
    amount: number;
    showLigthBox: boolean;
    showCart: boolean;
    isPhoneNav: boolean;
    cartItems: CartItem[];
    shippingCost: number;
    orderTotal: number;
    allProducts: Product[];

}
const initialState: CartState = {
    total: 0,
    amount: 0,
    showLigthBox: false,
    showCart: false,
    isPhoneNav: false,
    cartItems: [],
    shippingCost: 0,
    orderTotal: 0,
    allProducts: []
}

export const getAllProductsAsync = createAsyncThunk(
    'product/getAllProducts',
    async () => {
        const response = ajaxGet('/api/get-all-station-products')
        // The value we return becomes the `fulfilled` action payload
        return await response.then(r => r.data);
        //return [];
    }
);


export const checkoutWithStripeAsync = createAsyncThunk(
    'product/checkout',
    async (cartItems, { getState }) => {
        const state = getState() as RootState;
        const jwtToken = state.signin.jwtToken;
        const response = ajaxPostWithJWT('/api/checkout-with-stripe', { cartItems: cartItems }, jwtToken);
        // The value we return becomes the `fulfilled` action payload
        return await response.then(r => r.data);
        return [];
    }
);
function updateCartSum(state: CartState, cart: Cart) {
    state.total = cart.getTotalItems();
    state.amount = roundTo(cart.getAmount(), 2);
    state.orderTotal = state.amount + (state.shippingCost ? state.shippingCost : 0)
}
export const productSlice = createSlice({
    name: 'product',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        addToCart: (state, action: PayloadAction<CartItem>) => {
            const cart = new Cart(state.cartItems);
            cart.addCartItem(action.payload);
            updateCartSum(state, cart);

        },
        removeFromCart: (state, action: PayloadAction<CartItem>) => {
            const cart = new Cart(state.cartItems);
            cart.decreaseCartItem(action.payload);
            updateCartSum(state, cart);
        },

        addProduct: (state, action: PayloadAction<Product>) => {
            console.log(`adding product:${action.payload.productId}`);
            const cart = new Cart(state.cartItems);
            cart.addProduct(action.payload, 1);
            updateCartSum(state, cart);

        },
        removeProduct: (state, action: PayloadAction<Product>) => {
            console.log(`removing product:${action.payload.productId}`);
            const cart = new Cart(state.cartItems);
            cart.addProduct(action.payload, -1);
            updateCartSum(state, cart);

        },
        updateCart: (state, action: PayloadAction<CartItem>) => {
            const cart = new Cart(state.cartItems);
            cart.updateCartItem(action.payload);
            updateCartSum(state, cart);
        },
        setShowLightbox: (state, action) => {
            state.showLigthBox = action.payload;
        }

    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder.addCase(checkoutWithStripeAsync.pending, (state) => {
            console.log(`Checking out in progress.`);
        })
            .addCase(checkoutWithStripeAsync.fulfilled, (state) => {
                console.log(`Checking out in progress.`);
            })
            .addCase(getAllProductsAsync.pending, (state) => {
                console.log(`Get all products in progress.`);
            })
            .addCase(getAllProductsAsync.fulfilled, (state, action: PayloadAction<Product[]>) => {
                console.log(`Got all station products`);
                state.allProducts = action.payload;

            })
            .addCase(getAllProductsAsync.rejected, (state) => {
                console.log(`Got all station products rejected`);


            });
    }
}
);

export const { addToCart, updateCart, addProduct, removeProduct, removeFromCart, setShowLightbox } = productSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectShowCart = (state: RootState) => state.product.showCart;
export const selectIsPhoneNave = (state: RootState) => state.product.isPhoneNav;
export const selectShowLightBox = (state: RootState) => state.product.showLigthBox;
export const selectAmount = (state: RootState) => state.product.amount;
export const selectTotal = (state: RootState) => state.product.total;
export const selectCartItems = (state: RootState) => state.product.cartItems;
export const selectShippingCost = (state: RootState) => state.product.shippingCost;

export const selectOrderTotal = (state: RootState) => state.product.orderTotal;
export const selectProducts = (state: RootState) => state.product.allProducts;



export default productSlice.reducer;

