import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./cartbutton.css";

import {
  RiMenu3Line,
  RiCloseLine,
  RiAccountPinCircleLine,
} from "react-icons/ri";
import logo from "../../assets/everpx_logo.jpg";
import { PopupMenu } from "react-simple-widgets/dist/popup-menu";
import Dropdown from "react-bootstrap/Dropdown";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { selectSigninStatus } from "components/signin/sigin_slice";
import { useDispatch } from "react-redux";
import { selectTotal } from "components/product-detail/product_slice";

export const CartButton = () => {
  const siginStatus = useAppSelector(selectSigninStatus);
  const total = useAppSelector(selectTotal);
  const navigate = useNavigate();
  const handleCartClick = () => {
    navigate("/cartItemList");
  };

  if (total === 0) {
    return <></>;
  }

  // <Image src={images.avatar} height={50} width={50} alt="avatar" />
  return (
    <div className="flex-ac">
      <div className="flex-ac">
        <div className="cart" onClick={handleCartClick}>
          {total > 0 && <span className="flex-ac-jc">{total}</span>}
          <svg width="26" height="26" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M20.925 3.641H3.863L3.61.816A.896.896 0 0 0 2.717 0H.897a.896.896 0 1 0 0 1.792h1l1.031 11.483c.073.828.52 1.726 1.291 2.336C2.83 17.385 4.099 20 6.359 20c1.875 0 3.197-1.87 2.554-3.642h4.905c-.642 1.77.677 3.642 2.555 3.642a2.72 2.72 0 0 0 2.717-2.717 2.72 2.72 0 0 0-2.717-2.717H6.365c-.681 0-1.274-.41-1.53-1.009l14.321-.842a.896.896 0 0 0 .817-.677l1.821-7.283a.897.897 0 0 0-.87-1.114ZM6.358 18.208a.926.926 0 0 1 0-1.85.926.926 0 0 1 0 1.85Zm10.015 0a.926.926 0 0 1 0-1.85.926.926 0 0 1 0 1.85Zm2.021-7.243-13.8.81-.57-6.341h15.753l-1.383 5.53Z"
              fill="hsl(220, 14%, 75%)"
              fillRule="nonzero"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};
