import React, { useRef } from "react";
import { useNavigate, useParams, createSearchParams } from "react-router-dom";
import "./signin.css";
import { useTranslation } from "react-i18next";
import { useForm, SubmitHandler } from "react-hook-form";

import { useAppSelector, useAppDispatch } from "../../app/hooks";
import SignForm from "./Sign_form";
import { ajaxPost } from "service/http";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { AxiosError } from "axios";

type ForgetPasswordFields = {
  resetPasswordCode: string;
  password: string;
  passwordRepeat: string;
};
export const ResetPasswordPage = () => {
  const { resetpasswordCode } = useParams();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const passwordIsRequired = t("passwordIsRequired");
  const passwordMinLen = t("passwordMinLen", { minLen: 10 });

  const resetPassword = t("reset_password");

  const resetPasswordSchema = Yup.object().shape({
    password: Yup.string().required(passwordIsRequired).min(10, passwordMinLen),
    confirmPwd: Yup.string()
      .required(passwordIsRequired)
      .oneOf([Yup.ref("password")], t("password_not_match")),
    resetpasswordCode: Yup.string(),
  });

  const formOptions = { resolver: yupResolver(resetPasswordSchema) };
  interface ResetPaswordRequest
    extends Yup.InferType<typeof resetPasswordSchema> {}
  const { register, handleSubmit, reset, formState } = useForm(formOptions);
  const { errors } = formState;

  //useForm<ForgetPasswordFields>();

  //SubmitHandler<ForgetPasswordFields>
  const onSubmit = async (data) => {
    console.log(`submitted data:${JSON.stringify(data)}`);
    console.log(`resetpasswordCode:${resetpasswordCode}`);
    //dispatch(singupAsync(data));
    try {
      const resp = await ajaxPost("/api/resetpassword", {
        password: data.password,
        resetpasswordCode: resetpasswordCode,
      });
      navigate("/reset-password-success");
    } catch (e: any) {
      if (e.response) {
        navigate({
          pathname: "/reset-password-failure",
          search: `?${createSearchParams(e.response.data)}`,
        });
      } else {
        navigate("/server-error");
      }
    }
  };

  const fields = (
    <div className="fields-container">
      <div className="horizontalLine"></div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <input
          type="password"
          key="passwordkey1"
          placeholder={t("enter_password")}
          {...register("password")}
        ></input>
        {<p className="error-message">{errors.password?.message}</p>}
        {errors.password?.type === "minLength" && (
          <p className="error-message">{passwordMinLen}</p>
        )}

        <input
          type="password"
          key="passwordkey2"
          placeholder={t("enter_password")}
          {...register("confirmPwd")}
        ></input>
        {<p className="error-message">{errors.confirmPwd?.message}</p>}
        <button type="submit" className="btn-login">
          {resetPassword}
        </button>
      </form>
    </div>
  );
  return <SignForm formFieldsEle={fields} />;
};
