
const millisecondsPerMinute = 1000 * 60;
const millisecondsPerHour = millisecondsPerMinute * 60;
const millisecondsPerDay = millisecondsPerHour * 24;

function getTimeDifference(date1: Date, date2: Date, milliSecondsInUnit: number): number {
    const timeDifferenceInMilliseconds = Math.abs(date2.getTime() - date1.getTime());
    return Math.floor(timeDifferenceInMilliseconds / milliSecondsInUnit);
}

export function getHoursDifference(date1: Date, date2: Date): number {
    return getTimeDifference(date1, date2, millisecondsPerHour);
}

export function getMinutesDifference(date1: Date, date2: Date): number {
    return getTimeDifference(date1, date2, millisecondsPerMinute);
}


export function getDaysDifference(date1: Date, date2: Date): number {
    return getTimeDifference(date1, date2, millisecondsPerDay);
}

export function roundTo(n: number, place: number) {
    return Math.round(parseFloat(n + "e+" + place)) / (parseFloat("1e" + place));
}

export function getPriceInDollar(priceInCents: number): number {
    return roundTo(priceInCents / 100.0, 2);
}