import { useAppSelector } from "app/hooks";
import { CountryVO } from "app/models/country_vo";
import { CustomerProfileVO } from "app/models/customer_profile_vo";
import Navbar from "components/navbar/Navbar";
import { selectJwtToken } from "components/signin/sigin_slice";
import { Footer } from "containers";
import React, { Suspense, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ajaxGetWithJWT } from "service/http";
import "./youraccount.scss";
export const YourAccount = () => {
  const { t } = useTranslation();
  const jwtToken = useAppSelector(selectJwtToken);

  const [editMode, setEditMode] = useState<boolean>(false);
  const [msg, setMsg] = useState<string | null>(null);

  const [customerProfileVO, setCustomerProfileVO] =
    useState<CustomerProfileVO | null>(null);

  const [countryList, setCountryList] = useState<CountryVO[]>([]);

  //const [orders, setOrders] = useState<StationProductOrderVO[]>([]);
  const navigate = useNavigate();

  const getYourAccount = () => {
    ajaxGetWithJWT("/api/get-your-account", jwtToken)
      .then((res) => {
        if (res.data) {
          const profileVO = res.data as CustomerProfileVO;
          setCustomerProfileVO(profileVO);
          if (profileVO.isNew) setEditMode(true);
        }
      })
      .catch((error) => {
        setMsg(t("error_get_your_account"));
        console.log(`Error when retrieving customer orders:${error.data}`);
      });
  };

  const getCountryList = () => {
    ajaxGetWithJWT("/api/get-country-list", jwtToken)
      .then((res) => {
        if (res.data) {
          const countries = res.data as CountryVO[];
          setCountryList(countries);
        }
      })
      .catch((error) => {
        setMsg(t("error_get_your_account"));
        console.log(`Error when retrieving customer orders:${error.data}`);
      });
  };

  useEffect(() => {
    if (jwtToken === null) {
      navigate("/signin");
      return;
    }
    getYourAccount();
    getCountryList();
  }, []);

  const YourAccountForm = ({ profile }) => {
    const {
      register,
      handleSubmit,
      watch,
      formState: { errors },
    } = useForm<CustomerProfileVO>({ defaultValues: profile });
    const onSubmit: SubmitHandler<CustomerProfileVO> = (data) => {
      console.log(`submitted data:${JSON.stringify(data)}`);
    };
    return (
      <div className="account-form-container">
        <form onSubmit={handleSubmit(onSubmit)}>
          <AccountItem title={t("email")} value={customerProfileVO?.email} />

          <div className="flex-col-start">
            <label className="fieldLabel">{t("firstname_cap")}</label>
            <input
              type="text"
              key="firstnameKey"
              {...register("firstName", {
                required: true,
              })}
            />
          </div>

          <div className="flex-col-start">
            <label className="fieldLabel">{t("lastname_cap")}</label>
            <input
              type="text"
              key="lastnameKey"
              {...register("lastName", {
                required: true,
              })}
            />
          </div>
          <div className="flex-col-start">
            <label className="fieldLabel">{t("country")}</label>

            <select
              key="countryKey"
              {...register("countryCode", {
                required: true,
              })}
            >
              {countryList.map((c) => (
                <option value={c.countryCode}>{c.countryName}</option>
              ))}
            </select>
          </div>

          <div className="flex-col-start">
            <label className="fieldLabel">{t("city_cap")}</label>
            <input
              type="text"
              key="cityKey"
              {...register("city", {
                required: true,
              })}
            />
          </div>

          <div className="flex-col-start">
            <label className="fieldLabel">{t("postalcode_cap")}</label>
            <input
              type="text"
              key="postcodeKey"
              {...register("postcode", {
                required: true,
              })}
            />
          </div>

          <div className="flex-row-sb">
            <label className="margin-right width20">{t("bill_address")}</label>
            <textarea
              className="width80"
              key="billAddresskey"
              placeholder={t("street_no")}
              {...register("address", {
                required: true,
              })}
            />
          </div>
        </form>
      </div>
    );
  };
  const AccountItem = ({ title, value }) => {
    return (
      <div className="account-item">
        <div className="account-item-title">{title}</div>
        <div className="account-item-value">{value}</div>
      </div>
    );
  };
  const yourAccountContainer = (
    <div className="your-account-container">
      <div className="flex-row-sb underline">
        <h2>{t("your_account")}</h2>
        <span>{t("edit_cap")}</span>
      </div>
      <div className="account-item-container">
        <AccountItem title="Email" value="hello@hotmail.com" />
      </div>
      {editMode && <YourAccountForm profile={customerProfileVO} />}
    </div>
  );

  return (
    <>
      <div>
        <Suspense fallback={null}>
          <div>
            <Navbar />
            {yourAccountContainer}
          </div>

          <Footer />
        </Suspense>
      </div>
    </>
  );
};
