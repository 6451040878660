import React, { useEffect, useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { SignResponse } from "./sign_response";
import "./signin.css";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { resetStatus } from "./signup_slice";

export const SignupAlreadyRegisteredPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const signupStatus = useAppSelector((state) => state.signup.status);

  const msgEmailSignedup = t("email_already_signup");
  const msgGotoSigninPage = t("goto_signin");

  const gotoSigninPage = () => navigate("/signin");

  useEffect(() => {
    dispatch(resetStatus());
  }, []);

  const activationSuccess: JSX.Element = (
    <div>
      <div className="warn">
        <p>{msgEmailSignedup}</p>
      </div>
      <div className="spacer" />
      <button type="button" className="btn-success" onClick={gotoSigninPage}>
        {msgGotoSigninPage}
      </button>
    </div>
  );
  return <SignResponse messageBox={activationSuccess} />;
};
