import Navbar from "components/navbar/Navbar";
import { Footer } from "containers";
import React, { Suspense } from "react";
import "./legal.scss";
export const PrivacyPolicy = () => {
  const privacyPolicy = (
    <div className="legal">
      <h2> Privacy Policy</h2>
      <p>
        We at Everpx (“we”, or “us”) recognize the importance of privacy and
        confidentiality of personal information.
        <br /> This Privacy Policy sets out the ways in which we collect, use
        and disclose information in connection with the operation of the
        Platform, including personal information about customers and visitors.
        This Privacy Policy also applies to the use of the website via your
        mobile device.
      </p>
      <br />
      <h3>COLLECTION OF INFORMATION</h3>
      <h4>Information That You Provide To Us:</h4>
      <p>
        <ul>
          <li>
            contact information including: name; address; phone number; email
            address;
          </li>
          <li>
            purchase and payment information including: payment information that
            you intend to purchase with; billing and delivery address; No credit
            card or ewallet information is collected, it is governed by third
            party payment processor such as Sripe, Paypal etc.
          </li>
          <li>
            information about your purchases including: the goods purchased and
            how many;
          </li>
          <li>
            information about and to support complaints made in connection with
            the website or goods and/or services purchased via the website;
          </li>
        </ul>
      </p>
      <h4>Information That We Collect Automatically:</h4>
      <p>
        <ul>
          <li>
            A copy of any correspondence with us, including any verification
            information we ask for, if you contact us for any reason (such as
            our customer service department chat service, telephone or email).
          </li>
          <li>
            Details of transactions and communications over the Platform,
            including the types and specifications of the goods sold and
            purchased, pricing and delivery information, any dispute records.
          </li>
        </ul>
      </p>
      <h4>Information That We Receive From Third Parties:</h4>
      <p>
        We may receive personal information about you from:
        <ul>
          <li>
            Social media platforms if you choose to register for a Platform
            account via a social media account. Exactly what information we
            receive will depend on your privacy settings with the applicable
            platform, but would typically include basic public profile
            information such as:
            <ul>
              <li> Your social media user name, nickname or username </li>
              <li>
                Your social media profile picture · Country · Company name (if
                applicable).
              </li>
            </ul>
          </li>
          <li>
            Third parties that are engaged by us to provide verification
            services, such as conducting suitable money laundering, fraud and
            “Know Your Customer” checks on Buyers and Sellers. This includes
            your name, email address, company details, and contact information.
            We will only collect personal information from third parties where
            we have checked that these third parties either have your consent or
            are otherwise legally permitted or required to disclose your
            personal information to us.
          </li>
        </ul>
      </p>
      <h3>USE OF PERSONAL DATA</h3>
      <p>
        We collect and use your personal information for the following purposes:
      </p>
      <ul>
        <li>
          Verifying account registrations, including conducting fraud, security,
          “Know Your Customer” and anti-money laundering checks in order to
          approve your Seller account;
        </li>

        <li>
          Setting up / administering buyer accounts, including providing log-in
          IDs and setting up payment / transaction accounts;
        </li>
        <li>
          Providing customer service support, such as responding to your
          queries, feedback and managing claims and/or disputes;
        </li>
        <li>
          Providing/supporting general services, facilitating communication
          between Buyers and Everpx, processing transactions and payments;
        </li>
        <li>
          supporting Buyer and/or Seller clearance declarations / applications
          where made via the Platform;{" "}
        </li>
        <li>
          Assessing and monitoring account security and transaction risks, such
          as detecting and preventing fraud, money laundering and other security
          incidents;{" "}
        </li>
        <li>
          Sending and serving tailored marketing and advertising that we believe
          will be of interest to you based on the ways in which you use everpx
          products your browsing records, and order history
        </li>
        <li>
          Conducting research and analytics to ensure the security of our
          website, to improve the content and layout of the website, and to
          improve our website, product offerings and services;
        </li>
        <li>
          We may also use your personal information for other purposes that are
          compatible with the purposes we have disclosed to you if and where
          this is permitted by applicable data protection laws. You can request
          further information about the compatibility of other purposes upon
          request.
        </li>
      </ul>
      <h3>DISCLOSURE OR SHARING OF PERSONAL INFORMATION</h3>
      <p>
        We disclose/share personal information with the following categories of
        recipients:
      </p>
      <ul>
        <li>
          Third party partners and service providers and/or affiliates engaged
          by or working with us to assist us in providing services to you or who
          otherwise process personal information for purposes described in this
          Privacy Policy or notified to you when we collect your personal
          information. Categories of these partners and service providers
          include:
          <ul>
            <li>
              our business partners (for example, in order for them to provide
              you with discounts or offers that may interest Buyers or
              prospective Buyers) where you have consented to receiving such
              information from them if required by applicable data protection
              laws;
            </li>
            <li>
              Marketing and advertising partners, such as Google, Twitter,
              Facebook, Instagram etc., and providers of analytics services
              relating to users’ behavior such as Appsflyer, Criteo, so that
              they may tailor the content you see when visiting our Platform for
              analytics and/or advertising purposes. These partners may combine
              information they collect on our Platform with data on their
              platforms and data they collect from other websites or through
              other sources in order to conduct targeted advertising. The
              processing activities of these third parties marketing platforms
              are governed by their own privacy policies, not this Privacy
              Policy (please refer to section F “COOKIES” below);
            </li>
            <li>
              payment service providers that provide payment processing
              services. The activities of payment service providers may be
              governed by their own privacy policies, not this Privacy Policy;
            </li>
            <li>
              credit risk assessment providers to conduct risk assessment on
              Sellers to determine whether a Seller can be permitted to make an
              advance withdrawal;
            </li>
            <li>
              logistics partners for providing delivery services , including
              return and exchange of products, and warehousing services for
              dropshipping partners;
            </li>
            <li>
              customs agents for customs clearances purposes, where applicable
              to support international sales/purchases;
            </li>
            <li>
              customer service support providers to provide after-sale services
              or other customer service support;
            </li>
            <li>
              risk control service providers to assess the security of accounts
              and transaction risks;
            </li>
            <li>
              third party rating / reviewing service providers to carry out
              reviews of our services with customers if you choose to
              participate in reviewing or rating Platform products and/or
              services;
            </li>
            <li>
              To our professional advisers, law enforcement agencies, insurers,
              government and regulatory and other organizations where we believe
              it is necessary to comply with applicable laws or to exercise,
              establish or defend our legal rights or protect your vital
              interests or those of any other person; or as otherwise required
              or permitted by applicable laws.
            </li>
            <li>To any other person with your consent to the disclosure.</li>
          </ul>
        </li>
      </ul>
      <h3>RETENTION</h3>
      <p>
        We will retain your personal information for as long as we have an
        ongoing legitimate business need to do so, to provide services or
        products to you, or as required or permitted by applicable laws. When we
        have no ongoing legitimate business need to process your personal
        information, we will either delete or anonymize it, or if this is not
        possible (for example, because your personal information has been stored
        in backup archives), then we will securely store your personal
        information and isolate it from any further processing until deletion is
        possible. How long your data is kept for will also depend on how you use
        our website (for example if you are logged in as a member or you are
        visiting our website as a guest) and what the information you provide to
        us is used for. For example, if you are a registered member, certain
        data will be retained for a length of time following termination or
        deletion of your account, in order to comply with applicable laws [(for
        example, if a court order is received about your account, we would
        retain your account data for longer than the usual retention period when
        an account is deleted)] or in case of disputes. In other instances, the
        period of retention may be determined according to your activity on the
        Platform or to comply with a specific arbitration period/resolve a
        dispute.
      </p>
      <h3>RIGHTS REGARDING PERSONAL DATA</h3>
      <p>
        Under applicable laws, you may have rights of access to personal
        information held by us and other rights. If you have any questions
        regarding this Privacy Policy or if you wish to access or correct your
        personal information as a user of everpx.com, please click
        <a href="https://www.everpx.com/about">here</a> to send your concerns.
      </p>
      <h3>MINORS </h3>
      <p>
        The Platform is intended for adults and users must be over 18 to use our
        Platform. We do not intend to sell any of our products or services to
        minors, as defined by applicable law. If a minor has provided us with
        personal information without parental or guardian consent, the parent or
        guardian should contact DataProtection@everpx.com to remove the
        information.
      </p>
      <h3>SECURITY MEASURES</h3>
      <p>
        We implement appropriate technical and organisational measures to
        prevent unauthorized access to the Site, to maintain data accuracy and
        to ensure the correct use of the information we hold. Upon becoming
        aware of a data breach, we will notify you and the regulatory
        authorities in accordance with the timescales and scope required by
        applicable data protection law. For registered users of the Platform,
        some of your information can be viewed and edited through your account,
        which is protected by a password. It is your responsibility to keep your
        password secure and confidential. We recommend that you do not divulge
        your password to anyone. We have instructed our personnel to never ask
        you for your password. If you share a computer with others, you should
        not save your log-in information (e.g., user ID and password) on that
        shared computer. Remember to sign out of your account and close your
        browser window when you have finished your session No data transmission
        over the internet or any wireless network can be guaranteed to be
        perfectly secure. As a result, while we use appropriate technical and
        organizational measures to protect the information we hold for you, we
        cannot guarantee the security of any information you transmit over the
        internet.
      </p>
      <h3>CHANGES TO THIS PRIVACY POLICY</h3>
      <p>
        We may update this Privacy Policy from time to time in response to
        changing legal, technical or business developments. When we update our
        Privacy Policy, we will take appropriate measures to inform you,
        consistent with the significance of the changes we make and as required
        by applicable law, such as by posting an amended Privacy Policy on the
        Platform.
      </p>
      <h3>LANGUAGE</h3>
      <p>
        If there is any conflict between the English version and another
        language version of this Privacy Policy, the English version shall
        prevail.
      </p>
      <h3>HOW TO CONTACT US</h3>
      <p>
        For data subject rights, please contact the Data Protection Officer:
        DataProtection@everpx.com.
      </p>
    </div>
  );
  return (
    <div>
      <Suspense fallback={null}>
        <div>
          <Navbar />
          {privacyPolicy}
        </div>

        <Footer />
      </Suspense>
    </div>
  );
};
