import React from "react";
import ReactDom from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { Provider } from 'react-redux';
import { store,persistor } from './app/store';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n'; // Import your i18n instance
import { RSWGlobalStyle } from "react-simple-widgets/dist/style";
import { PersistGate } from 'redux-persist/integration/react';
//<I18nextProvider i18n={i18n}>
//</I18nextProvider>
ReactDom.render(
   <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
    <BrowserRouter>
       <I18nextProvider i18n={i18n}>
           <RSWGlobalStyle />
           <App />
      </I18nextProvider>
   </BrowserRouter>
   </PersistGate>
  </Provider>,
  document.getElementById("root"));