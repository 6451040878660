import React, { Suspense } from 'react'
import { useNavigate } from 'react-router-dom';

import './about.css';
import {useTranslation} from 'react-i18next';
import torontoCNTower from '../../assets/toronto_cn_tower.jpg';
import peopleTablePic from '../../assets/people_table.jpg';

import { Navbar } from '../../components';
import Footer from '../footer/Footer';
import  Feedback  from '../feedback/Feedback';

const About = () => {
    const {t} = useTranslation();
   
    const AboutUs = () => (
         <div className="everpx__header section__padding" id="about">
            <div className="everpx__header-content">
                <h2 className="gradient__text">{t("about")}</h2>
                <p>{t("aboutDesc")}</p>
               
            </div>
            <div className="everpx__header-image">
                <img src = {peopleTablePic} />
            </div>
        </div>);

       const ContactUs = () => (
         <div className="everpx__header section__padding" id="about">
            
            <div className="everpx__header-feedbackform">
                 <Feedback />
            </div>
            
            <div className="everpx__header-content-right">
                <h1 className="gradient__text">{t("contact_us")}</h1>
                <p>{t("questions_feedback")} </p>
            </div>
           
        </div>);

/* <img src = {torontoCNTower} /> */
    return (
        <>
        <div>
            <Suspense fallback={null}>
            <div className='gradient__bg'>
                <Navbar />  
                <AboutUs />
                <ContactUs />
            </div>
           
            <Footer />
           </Suspense>
        </div>
       
        </>
    ) ;
}

export default About