import React from 'react';
import everpxLogo from '../../assets/everpx_logo.jpg';
import './footer.css';


import {useTranslation} from 'react-i18next';
import { Link } from 'react-router-dom';

const Footer = () =>{
    const {t} = useTranslation();

 return (
  <div className="gpt3__footer section__padding">
    <div className="gpt3__footer-links">
      <div className="gpt3__footer-links_logo">
        <img src={everpxLogo} alt="gpt3_logo" />
        <p>Everpx Inc</p>
        <p>270-11 Davenport Road, Toronto  Canada </p>
      </div>
      <div className="gpt3__footer-links_div">
        <h4>{t("links")}</h4>
        <p><Link to="https://twitter.com/Everpx_">{t("socialMedia")}</Link></p>
      </div>
      <div className="gpt3__footer-links_div">
        <h4>Legal</h4>
       
        <p><Link to="/term-of-service">{t("termsConditions")}</Link></p>
         <p><Link to="/privacy-policy">{t("privacyPolicy")}</Link></p>
     
       
      </div>
      <div className="gpt3__footer-links_div">
        <h4>{t("getInTouch")}</h4>
        
        <p>sales@everpx.com</p>
      </div>
    </div>

    <div className="gpt3__footer-copyright">
      <p>{t("2023_everpx")}. {t("all_rights_reserved")}</p>
    </div>
  </div>
);
 }
export default Footer;

/*
 <div className="gpt3__footer-heading">
      <h1 className="gradient__text">{t('do_you_want_to_backup_today')}</h1>
    </div>

    <div className="gpt3__footer-btn">
      <p>{t('get_started')}</p>
    </div>

    <div className="gpt3__footer-links">
      <div className="gpt3__footer-links_logo">
        <img src={everpxLogo} alt="gpt3_logo" />
        <p>270-11 Davenport Road,Toronto <br />{t('all_rights_reserved')}</p>
      </div>
      <div className="gpt3__footer-links_div">
        <h4>{t("links")}</h4>
        <p>{t("socialMedia")}</p>
       
      </div>
      <div className="gpt3__footer-links_div">
        <h4>Company</h4>
        <p>{t("termsConditions")} </p>
        <p>{t("privacyPolicy")}</p>
        <p>{t("contact")}</p>
      </div>
      <div className="gpt3__footer-links_div">
        <h4>Get in touch</h4>
        <p>Crechterwoord K12 182 DK Alknjkcb</p>
        <p>085-132567</p>
        <p>info@payme.net</p>
      </div>
    </div>
*/