import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  addProduct,
  addToCart,
  removeFromCart,
  removeProduct,
  selectAmount,
  selectCartItems,
  selectOrderTotal,
  selectShippingCost,
  selectTotal,
  updateCart,
} from "components/product-detail/product_slice";
import React from "react";
import "./cart.scss";
import { sampleProduct } from "containers/product_info/sample_produdct";
import { CounterButton } from "components/counter-button/CounterButton";
import { useTranslation } from "react-i18next";
import {
  createSearchParams,
  Link,
  Navigate,
  useNavigate,
} from "react-router-dom";
import { getPriceInDollar, roundTo } from "service/utils";
import { ajaxPost, ajaxPostWithJWT } from "service/http";
import {
  selectJwtToken,
  selectSigninStatus,
} from "components/signin/sigin_slice";
import { RiDeleteBinLine } from "react-icons/ri";
import { useHover } from "hooks/useHover";
import { CartItem } from "components/product-detail/product";

export const CartItemList = () => {
  const cartItems = useAppSelector(selectCartItems);
  const amount = useAppSelector(selectAmount);
  const itemCount = useAppSelector(selectTotal);
  const shippingCost = useAppSelector(selectShippingCost);
  const orderTotal = useAppSelector(selectOrderTotal);
  const jwtToken = useAppSelector(selectJwtToken);
  const siginStatus = useAppSelector(selectSigninStatus);

  const navigate = useNavigate();
  const [hoverRef, isHovered] = useHover();

  const { t } = useTranslation();
  //const cartItem: CartItem = { product: sampleProduct, count: 10 };
  //const cartItems = [cartItem, cartItem];
  const dispatch = useAppDispatch();

  const handleCheckout = () => {
    console.log(`jwttoken:${jwtToken}`);
    console.log(`siginStatus:${siginStatus}`);
    const params = { redirectto: "cartItemList" };
    if (jwtToken == null) {
      navigate({
        pathname: "/signin",
        search: `?${createSearchParams(params)}`,
      });
      return;
    }
    ajaxPostWithJWT("/api/checkout-with-sripe", cartItems, jwtToken)
      .then((res) => {
        if (res.data.url) {
          window.location.href = res.data.url;
        } else {
        }
      })
      .catch((error) => {
        navigate({
          pathname: "/signin",
          search: `?${createSearchParams(params)}`,
        });
        // console.log(`Error when checking out with sripe:${error.data}`);
        //navigate("/cartItemList");
      });
  };

  const Button = ({ title, style, icon, onClick }) => {
    return (
      <button className={`btn ${style}`} onClick={onClick}>
        {icon && icon}
        <span> {title && title}</span>
      </button>
    );
  };

  const CartItemView = (cartItem: CartItem) => {
    const { t } = useTranslation();
    return (
      <article className="cartitem flex-ac-jb" key={cartItem.product.productId}>
        <div className="flex-ac">
          <img
            src={cartItem.product.images[0].imgThumbnail}
            height={40}
            width={40}
            alt="product image"
          />

          <div className="cartitem--info">
            <h5>{t(cartItem.product.title)}</h5>
            <h5>
              ${getPriceInDollar(cartItem.product.promotionPrice)} x{" "}
              {cartItem.count}
            </h5>
          </div>
        </div>

        <div className="cartitem--info-right">
          {" "}
          <CounterButton
            getCount={() => cartItem.count}
            onDesc={() => dispatch(removeProduct(cartItem.product))}
            onInc={() => dispatch(addProduct(cartItem.product))}
          />
          <Button
            style={{ height: "20px" }}
            title=""
            onClick={() =>
              dispatch(updateCart({ product: cartItem.product, count: 0 }))
            }
            icon={<RiDeleteBinLine color="#C3CAD9" size={24} />}
          />
          <h5 className="cartitem--price">
            <span>
              {" "}
              $
              {getPriceInDollar(
                cartItem.product.promotionPrice * cartItem.count
              )}
            </span>
          </h5>
        </div>
      </article>
    );
  };
  const emptyCart = (
    <div
      className="flex-ac-jc"
      style={cartItems && cartItems.length === 0 ? { margin: "3rem" } : {}}
    >
      <p>{t("your_cart_is_empty")}</p>
    </div>
  );
  const cartCotainer = (
    <div
      className="cart-container"
      style={cartItems && cartItems.length === 0 ? { height: "200px" } : {}}
    >
      <header>
        <h4>{t("shopping_cart")}</h4>
      </header>
      <div className="cart-items-header">
        <div>{t("price_cap")}</div>
      </div>

      <div>
        <div className="cart-items-container">
          {cartItems && cartItems.length > 0 ? (
            <div>
              {cartItems.map((cartItem) => (
                <CartItemView {...cartItem} />
              ))}
            </div>
          ) : (
            { emptyCart }
          )}
        </div>

        <div className="cart-items-footer">${amount}</div>
      </div>
    </div>
  );
  const checkoutContainer = (
    <div className="checkout-container2">
      <Button
        title={t("proceed_to_checkout")}
        style="checkout-btn"
        icon={null}
        onClick={handleCheckout}
      />
      <span className="term-privacy">
        {t("place_order_agree_terms")}&nbsp;
        <Link to="/term-of-service">{t("termsConditions")}</Link>
        &nbsp;{t("and")}&nbsp;
        <Link to="/privacy-policy">{t("privacyPolicy")}</Link>
      </span>

      <h5>{t("order_summary")}</h5>
      <div className="summary-item-container">
        <div>
          {t("items")}({itemCount}):
        </div>
        <div>${amount}</div>
      </div>
      <div className="summary-item-container">
        <div>{t("shipping_handling")}:</div>
        <div>{shippingCost}</div>
      </div>

      <div className="summary-total-container">
        <span>{t("order_total")}:</span>
        <span>${orderTotal}</span>
      </div>
    </div>
  );

  return cartItems.length === 0 ? (
    <Navigate to="/" />
  ) : (
    <div className="order-summary-container">
      {cartCotainer}
      {checkoutContainer}
    </div>
  );
};
