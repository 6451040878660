import { BraintreePayPalButtons, PayPalButtons } from "@paypal/react-paypal-js";
import React from "react";
import "./checkout.scss";
export const Checkout = () => {
  const checkOutSteps = (
    <div className="checkout-steps-container">
      <div className="checkout-step">
        <div className="checkout-step-title">
          <span>1. Shipping Address</span>
        </div>
        <div className="checkout-step-info">
          <div>Address</div>
          <div>Change</div>
        </div>
      </div>

      <div className="checkout-step">
        <div className="checkout-step-title">
          <span>2. Payment Method</span>
        </div>
        <div className="checkout-step-info">
          <div>PayPay GooglePay</div>
          <div>Change</div>
        </div>
      </div>
    </div>
  );

  const paypalButton = (
    <PayPalButtons
      createOrder={(data, actions) => {
        return actions.order.create({
          purchase_units: [
            {
              amount: {
                value: "1.99",
              },
            },
          ],
        });
      }}
      onApprove={(data, actions: any) => {
        return actions.order.capture().then((details) => {
          const name = details.payer.name.given_name;
          alert(`Transaction completed by ${name}`);
        });
      }}
    />
  );

  const placeOrderContainer = (
    <div className="placeorder-container">
      <div>{paypalButton} paypaybuttons</div>

      <button className="btn checkout-btn" onClick={() => {}}>
        <span> Place The Order</span>
      </button>
      <span className="term-privacy">
        By placing your order, you agree to Everpx's privacy notice and
        conditions of use.
      </span>

      <h5>Order Summary</h5>
      <div className="summary-item-container">
        <div>Items(3):</div>
        <div>2399.00</div>
      </div>
      <div className="summary-item-container">
        <div>Shipping & Handling:</div>
        <div>2.00</div>
      </div>

      <div className="summary-total-container">
        <span>Order Total:</span>
        <span>3455.909</span>
      </div>
    </div>
  );

  return (
    <div className="checkout-container">
      {checkOutSteps}
      {placeOrderContainer}
    </div>
  );
};
