import React, { useReducer } from "react";
import { FC, Suspense } from "react";
import {
  Navigate,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

import "./signin.css";
import logo from "../../assets/everpx_logo.jpg";
import axios, { AxiosResponse } from "axios";
import { GoogleLogin } from "react-google-login";
import { useTranslation } from "react-i18next";

import {
  INITIAL_STATE,
  LoginActinKind,
  loginReducer,
} from "service/login_reducer";
import {
  loginAsync,
  selectJwtToken,
  selectSigninStatus,
  SignInStatus,
  updateLoggedin,
  updateLoginFailed,
} from "./sigin_slice";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import SignForm from "./Sign_form";
import GoogleSignin from "./google_signin";
import { OrLine } from "./or_line";
import { useForm, SubmitHandler } from "react-hook-form";
import { EmailPassword } from "app/models/email_password";
import { ajaxPost } from "service/http";

const { useState } = React;
const responseGoogle = (response) => {
  console.log(response);
};

function handleClick() {
  alert("Hello!");
}
const handleSocialLogin = (user) => {
  console.log(user);
};

const handleSocialLoginFailure = (err) => {
  console.error(err);
};

const Signin = () => {
  //redirectto
  //const { redirectto } = useParams();
  let [searchParams, setSearchParams] = useSearchParams();
  const redirectto = searchParams.get("redirectto");
  console.log(`redirectto=${redirectto}`);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [userName, setUserName] = useState("");

  const siginStatus = useAppSelector(selectSigninStatus);

  const [password, setPassword] = useState("");

  const [clickedLogin, setClickLogin] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<EmailPassword>();

  const onSubmit: SubmitHandler<EmailPassword> = async (data) => {
    setClickLogin(true);
    // dispatch(loginAsync(data));
    const response = ajaxPost("/api/login", {
      username: data.email,
      password: data.password,
    });
    // The value we return becomes the `fulfilled` action payload
    try {
      const loginResult = await response.then((r) => r.data);
      dispatch(updateLoggedin(loginResult));
    } catch (e) {
      dispatch(updateLoginFailed());
      return;
    }

    if (redirectto) {
      console.log(`redirectto to:/${redirectto}`);
      navigate(`/${redirectto}`);
    }
  };

  const onKeyUp = (event) => {
    if (event.key === "Enter") {
      handleSubmit(onSubmit);
    }
  };
  const emailRequired = t("emailIsRequired");
  const passwordIsRequired = t("passwordIsRequired");

  const fields = (
    <div>
      <div className="fields-container">
        <form onSubmit={handleSubmit(onSubmit)}>
          <input
            type="text"
            key="emailkey"
            placeholder={t("enter_email")}
            {...register("email", {
              required: true,
              pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
            })}
          />
          {errors.email?.type === "required" && (
            <p className="error-message">{emailRequired}</p>
          )}

          <input
            type="password"
            key="passwordkey"
            placeholder={t("enter_password")}
            onKeyUp={onKeyUp}
            {...register("password", { required: true })}
          ></input>
          {errors.password?.type === "required" && (
            <p className="error-message">{passwordIsRequired}</p>
          )}

          {clickedLogin && siginStatus === SignInStatus.FAILED && (
            <p className="error-message">{t("invalidEmailOrPassword")}</p>
          )}
          <button type="submit" className="btn-login">
            {t("sign_in")}
          </button>
        </form>
        <OrLine />

        <GoogleSignin />
      </div>
      <div className="passcontainer">
        <span className="signup">
          {" "}
          <a href="/signup">{t("createNewAccount")}</a>
        </span>
        <span className="forgetpass">
          {" "}
          <a href="/forget-password">{t("forget_password")}</a>
        </span>
      </div>
    </div>
  );
  const navTo = redirectto ? `/${redirectto}` : "/";
  return siginStatus === SignInStatus.SIGNED_IN ? (
    <Navigate to={navTo} />
  ) : (
    <SignForm formFieldsEle={fields} />
  );
};
//oauth secret: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
export default Signin;
