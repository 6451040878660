import React, { Suspense, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import "./yourorder.scss";
import { useTranslation } from "react-i18next";

import { Navbar } from "..";
import Footer from "../../containers/footer/Footer";
import {
  StationProductKeyVO,
  StationProductOrderDetailVO,
} from "app/models/station_product_order_detail_vo";
import { StationProductOrderVO } from "app/models/station_product_order_vo";
import { useAppSelector } from "app/hooks";
import { selectJwtToken } from "components/signin/sigin_slice";
import {
  selectCurrentOrder,
  selectCurrentOrderDetail,
} from "./yourorder_slice";

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { format } from "date-fns";
import { QRCode } from "react-qrcode-logo";
import { RiQrCodeLine } from "react-icons/ri";
import { Modal } from "react-bootstrap";
import { Invoice } from "./Invoice";

const LicenseQRModal = ({ show, prodKey, onHide }) => {
  const { t } = useTranslation();
  return (
    <div>
      <Modal
        size="lg"
        dialogClassName="modal-90w"
        centered
        className="modal_wrapper"
        show={show}
        onHide={onHide}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("license_qr_code")}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: "2rem" }}>
          <div className="license-qr-modal-container">
            <div>
              <h5>{prodKey.licencenseCode}</h5>
            </div>
            <QRCode
              value={prodKey.encodedLicense}
              fgColor="#0AF"
              logoImage="/FaviconLogo.jpg"
            />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export const OrderDetails = () => {
  const { t } = useTranslation();
  const navigation = useNavigate();

  const jwtToken = useAppSelector(selectJwtToken);
  const currentOrderDetail = useAppSelector(selectCurrentOrderDetail);
  const currentOrder = useAppSelector(
    selectCurrentOrder
  ) as StationProductOrderVO;

  const hasInvoice =
    currentOrder.orderInvoices && currentOrder.orderInvoices.length > 0;
  const [orderMsg, setOrderMsg] = useState<String>();
  const [stationProduct, setStationProduct] = useState<StationProductKeyVO>();
  const navigate = useNavigate();

  const [qrShow, setQRShow] = useState(false);

  /*const orderDate = format(
    new Date(currentOrderDetail.orderDate),
    "yyyy-MM-dd"
  ); */

  const orderDetailHeader = (
    <div className="order-detail-item-container">
      <div className="width20">&nbsp;</div>

      <div className="order-detail-item-title-container bold">
        <div>{t("item_name")}</div>
      </div>
      <div className="order-detail-item-amount bold">{t("item_subtotal")}</div>
    </div>
  );
  const OrderDetailItem = (orderItem: StationProductOrderDetailVO) => {
    const thumbnailImg = `https://www.everpx.com/${orderItem.imageThumbnail}`;

    return (
      <div className="order-detail-item-container">
        <div className="width20">
          <img src={thumbnailImg} className="order-detail-item-img"></img>
        </div>

        <div className="order-detail-item-title-container">
          <div className="order-detail-item-title">{orderItem.name}</div>
          <div className="order-detail-item-date">
            ${orderItem.price / 100.0}X{orderItem.count}
          </div>
        </div>
        <div className="order-detail-item-amount">
          ${(orderItem.price * orderItem.count) / 100.0}
        </div>
      </div>
    );
  };

  const orderDetail = (
    <div>
      <div className="order-detail-container">
        <span>
          {t("order_id")}: {currentOrder.orderId}
        </span>
        <span>
          {t("order_date")}: {currentOrder.orderDateTxt}
        </span>

        <span>
          {t("amount_cap")}: ${currentOrder.totalAmount / 100.0}
        </span>
      </div>
      {orderDetailHeader}
      <div className="underline" />
      {currentOrder.orderDetails.map((od) => (
        <OrderDetailItem {...od} />
      ))}
    </div>
  );

  const showQRModal = (prodKey: StationProductKeyVO) => {
    setStationProduct(prodKey);
    setQRShow(true);
  };
  const LicenseItem = (prodKey: StationProductKeyVO) => {
    return (
      <div className="license-item-container">
        <div>{prodKey.licencenseCode}</div>
        <div className="flex-row-start">{prodKey.license}</div>
        <div>{prodKey.status}</div>
        <div>
          <button onClick={() => showQRModal(prodKey)}>
            <RiQrCodeLine color="#f00" width={40} height={40} />
          </button>
        </div>
      </div>
    );
  };

  const licenseList = (
    <div className="license-container">
      <h4>{currentOrderDetail?.name}</h4>

      <div className="license-item-container underline">
        <div>{t("license_code")}</div>
        <div className="flex-row-start">{t("license_cap")}</div>
        <div>{t("status_cap")}</div>

        <div>{t("qr_code")}</div>
      </div>
      {currentOrder.orderDetails.map((od) => {
        return od.productKeys.map((pkey) => <LicenseItem {...pkey} />);
      })}

      {stationProduct && (
        <LicenseQRModal
          show={qrShow}
          prodKey={stationProduct}
          onHide={() => setQRShow(false)}
        />
      )}
    </div>
  );

  const orderDetailContainer = (
    <div className="your-orders-container">
      <div className="flex-row-sb">
        <h2>{t("order_detail")}</h2>
        <a className="link" onClick={() => navigation(-1)}>
          {t("back_to_orders")}
        </a>
      </div>

      <Tabs defaultActiveKey="detail" id="order-detail-tabs" className="mb-3">
        <Tab eventKey="detail" title={t("order_detail")}>
          {orderDetail}
        </Tab>
        <Tab eventKey="license" title={t("licenses_cap")}>
          {licenseList}
        </Tab>
        {hasInvoice && (
          <Tab eventKey="invoice" title={t("invoice_cap")}>
            <Invoice />
          </Tab>
        )}
      </Tabs>
    </div>
  );

  return (
    <>
      <div>
        <Suspense fallback={null}>
          <div>
            <Navbar />
            {orderDetailContainer}
          </div>

          <Footer />
        </Suspense>
      </div>
    </>
  );
};
