import React from "react";

import "./signin.css";
import { GoogleLogin } from "react-google-login";
import { useTranslation } from "react-i18next";

import {
  GoogleProfile,
  loginWithGoogleAsync,
  selectJwtToken,
} from "./sigin_slice";
import { useAppSelector, useAppDispatch } from "../../app/hooks";

const responseGoogle = (response) => {
  console.log(response);
};

const GoogleSignin = () => {
  const { t } = useTranslation();
  const jwtToken = useAppSelector(selectJwtToken);
  const dispatch = useAppDispatch();

  const onGoogleSignInSuccess = async (res) => {
    console.log("google login response:" + res);
    console.log("tokenId:" + res.tokenId);
    console.log("profileObj:" + JSON.stringify(res.profileObj));
    dispatch(loginWithGoogleAsync(res.profileObj as GoogleProfile));
    /*
    {"googleId":"115370206408686843551","imageUrl":"https://lh3.googleusercontent.com/a/AGNmyxaiHoGSBSFKnDM05BnguH7FRZwefzhm7baEUWZ4=s96-c","email":"vinson.zhang@gmail.com","name":"Vinson Zhang","givenName":"Vinson","familyName":"Zhang"}
    */
  };

  const fbStyle = { background: "#fff", color: "#3b5998" };
  const abActiveStyle = { background: "#EFF0EE", color: "#3b5998" };
  const enterUserName = t("enter_username_email");
  return (
    <GoogleLogin
      clientId="906733991777-hleu99hhn2ftianpvu9mieu7te19jrjm.apps.googleusercontent.com"
      buttonText={t("login_with_google")}
      onSuccess={onGoogleSignInSuccess}
      onFailure={responseGoogle}
      cookiePolicy={"single_host_origin"}
    />
  );
};
export default GoogleSignin;
